import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { ICharacteristicService } from './characteristics.sevice'
import { characteristicsModuleToken, characteristicsServiceToken, characteristicsUtilsServiceToken } from './characteristics.constants'
import { ICharacteristicsUtilsService } from './characteristics-utils.service'
import { CharacteristicsModuleDefinition } from './characteristics-module.types'
import * as CharacteristicsModuleExports from './characteristics.exports'
import { CoreModule } from '../core/core.module'

export const CharacteristicsModule: IModule<CharacteristicsModuleDefinition> = {
  name: characteristicsModuleToken,
  resolver: () => CharacteristicsModuleExports,
  initializer: ({ CharacteristicsService, CharacteristicsUtilsService }) => {
    Injector.register<ICharacteristicService>(
      characteristicsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        return new CharacteristicsService(httpClient)
      },
      Scope.Singleton
    )
    Injector.register<ICharacteristicsUtilsService>(
      characteristicsUtilsServiceToken,
      (injector) => {
        const characteristicsService = injector.resolve<ICharacteristicService>(characteristicsServiceToken)
        return new CharacteristicsUtilsService(characteristicsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule],
}
