import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { homeRoutePath } from 'home/home.constants'
import { crmRoutePath } from 'crm/crm.constants'
import { productsRoutePath } from 'products/products.constants'
import { tasksCalendarRoutePath, tasksRoutePath } from 'tasks/tasks.constants'
import { quotesRoutePath } from 'quotes/quotes.constants'
import { salesOrdersRoutePath } from 'sales-orders/sales-orders.constants'
import { crmRoute } from 'crm/crm.routes'
import { canActivateRoute } from 'common/routing/guarded-route/guarder-route.utils'
import { productRoute } from 'products/products.routes'
import { tasksCalendarRoute, tasksRoute } from 'tasks/tasks.routes'
import { quoteRoute } from 'quotes/quotes.routes'
import { salesOrdersRoute } from 'sales-orders/sales-orders.routes'
import { dashboardRoute, dashboardRoutePath } from 'dashboard/dashboard.routes'
import { setupRoutePath } from 'setup/setup.constants'
import { setupRoute } from 'setup/setup.routes'
import { shippingRoutePath } from 'shipping/shipping.constants'
import { IXtSidebarOption } from './sidebar.types'
import { vendorRoutePath } from '../../vendor/vendor.constants'
import { itemSourcesRoutePath } from '../../item-source/item-source.constants'
import { itemSourcesRoute } from '../../item-source/item-source.routes'

export function getSidebarOptions(): IXtSidebarOption[] {
  return [
    {
      name: 'Home',
      path: homeRoutePath,
      icon: SvgIconIds.HOME,
      exact: true,
      disabled: false,
    },
    {
      name: 'CRM',
      path: crmRoutePath,
      icon: SvgIconIds.CRM,
      disabled: !canActivateRoute(crmRoute.guards),
    },
    {
      name: 'Products',
      path: productsRoutePath,
      icon: SvgIconIds.PRODUCTS,
      disabled: !canActivateRoute(productRoute.guards),
    },
    {
      name: 'Item Sources',
      path: itemSourcesRoutePath,
      icon: SvgIconIds.INFO_ICON, // TODO: update icon
      disabled: !canActivateRoute(itemSourcesRoute.guards),
    },
    {
      name: 'Tasks',
      path: tasksRoutePath,
      icon: SvgIconIds.TASKS,
      disabled: !canActivateRoute(tasksRoute.guards),
    },
    {
      name: 'Quotes',
      path: quotesRoutePath,
      icon: SvgIconIds.QUOTES,
      disabled: !canActivateRoute(quoteRoute.guards),
    },
    {
      name: 'Sales Orders',
      path: salesOrdersRoutePath,
      icon: SvgIconIds.SALES_ORDERS,
      disabled: !canActivateRoute(salesOrdersRoute.guards),
    },
    {
      name: 'Shippings',
      path: shippingRoutePath,
      icon: SvgIconIds.SHIPPING,
      disabled: false,
    },
    {
      name: 'Dashboard',
      path: dashboardRoutePath,
      icon: SvgIconIds.DASHBOARD,
      disabled: !canActivateRoute(dashboardRoute.guards),
    },
    {
      name: 'Calendar',
      path: tasksCalendarRoutePath,
      icon: SvgIconIds.CALENDAR_ICON,
      disabled: !canActivateRoute(tasksCalendarRoute.guards),
    },
    {
      name: 'Settings',
      path: setupRoutePath,
      icon: SvgIconIds.SETTINGS,
      disabled: !canActivateRoute(setupRoute.guards),
    },
    {
      name: 'Vendors',
      path: vendorRoutePath,
      icon: SvgIconIds.SETTINGS,
      disabled: false, // TODO: fix me after vendors guards implementation
    },
  ]
}
