export const coreModuleToken = 'CoreModule'

export const dashboardServiceToken = 'DashboardService'
export const dictionaryServiceToken = 'DictionaryService'
export const navigationServiceToken = 'NavigationService'
export const errorHandlerServiceToken = 'ErrorHandlerService'
export const glAccountsServiceToken = 'GLAccountsService'
export const expenseCategoryServiceToken = 'ExpenseCategoryServiceToken'
export const crmRolesServiceToken = 'CRMRolesService'
export const uomServiceToken = 'UomService'
export const operationTypesServiceToken = 'OperationTypesService'
export const workCenterServiceToken = 'WorkCenterService'
export const termsServiceToken = 'TermsService'
export const salesRepServiceToken = 'SalesRepService'
export const sitesServiceToken = 'SitesService'
export const projectServiceToken = 'ProjectService'
export const sourceServiceToken = 'SourceService'
export const standardOperationServiceToken = 'StandardOperationService'
export const coreUtilsServiceToken = 'CoreUtilsService'
export const phoneServiceToken = 'PhoneService'
