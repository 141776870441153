import { IAsyncRoute, IRoute } from './common/routing/routing.types'
import { crmRoute } from './crm/crm.routes'
import { tasksCalendarRoute, tasksRoute } from './tasks/tasks.routes'
import { salesOrdersRoute } from './sales-orders/sales-orders.routes'
import { quoteRoute } from './quotes/quotes.routes'
import { homeRoute } from './home/home.routes'
import { processRoutes } from './common/routing/routing.utils'
import { productRoute } from './products/products.routes'
import { XtVersion } from './components/version/version'
import { setupRoute } from './setup/setup.routes'
import { dashboardRoute } from './dashboard/dashboard.routes'
import { vendorRoute } from './vendor/vendor.routes'
import { itemSourcesRoute } from './item-source/item-source.routes'
import { shippingRoute } from './shipping/shipping.routes'
import { purchaseRequestsRoute } from './purchase-requests/purchase-requests.routes'

const versionRoute: IRoute = {
  path: '/version',
  component: XtVersion,
}

const routes: Array<IRoute | IAsyncRoute<never>> = [
  homeRoute,
  crmRoute,
  tasksRoute,
  salesOrdersRoute,
  quoteRoute,
  productRoute,
  dashboardRoute,
  setupRoute,
  tasksCalendarRoute,
  vendorRoute,
  itemSourcesRoute,
  shippingRoute,
  versionRoute,
  purchaseRequestsRoute,
]

export const appRoutes = processRoutes(routes)
