import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MenuItem, TextField } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { FormField } from 'common/utils/form/form.components'
import { XtFileUpload } from 'components/controls/file-upload/file-upload'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import {
  DocumentDialogFormField,
  DocumentDialogFormLabel,
  IAttachedDocumentDialog,
  IDocumentDialogFormState,
} from './document-dialog.types'
import * as styles from './document-dialog.module.scss'
import { DocumentsFormSchema } from './document-dialog.validation'
import { setDefaultDocumentFormValues } from './document-dialog.utils'
import { UsedOnValue } from '../../documents.types'

export const XtDocumentDialog = React.memo(
  ({ onClose, onCreate, onUpdate, editedDocument, disabled = false, disableFileUpload }: IAttachedDocumentDialog) => {
    const { XtCharacteristics, useCharacteristics } = useCharacteristicsModule()

    const [loading, setLoading] = React.useState<boolean>(false)
    const {
      handleSubmit,
      control,
      formState: { isDirty: isFormDirty },
      setValue,
    } = useForm<IDocumentDialogFormState>({
      defaultValues: setDefaultDocumentFormValues(editedDocument),
      resolver: yupResolver(DocumentsFormSchema),
      mode: 'onBlur',
    })

    const characteristicsState = useCharacteristics(editedDocument?.document_characteristics || [])

    const isDirty = isFormDirty || characteristicsState.isDirty

    const onCancel = (): void => {
      // eslint-disable-next-line no-restricted-globals
      if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
        return
      }
      onClose()
    }

    const onSaveClick = useCallback(
      async (data: IDocumentDialogFormState) => {
        if (!editedDocument) {
          return
        }
        const callback = editedDocument.file_link || editedDocument.file ? onUpdate : onCreate
        setLoading(true)
        const successfulResult = await callback({
          ...editedDocument,
          ...data,
          document_characteristics: characteristicsState.characteristics,
          file: data.file!,
        })
        setLoading(false)
        if (successfulResult) {
          onClose()
        }
      },
      [onClose, onCreate, onUpdate, editedDocument, characteristicsState.characteristics]
    )

    useEffect(() => characteristicsState.setCharacteristics(editedDocument?.document_characteristics || []), [editedDocument])

    const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
      e.stopPropagation() // To prevent submitting parent forms
      const eventHandler = handleSubmit(onSaveClick)
      void eventHandler(e)
    }

    const onFileChange = (file: File | null): void => {
      setValue(DocumentDialogFormField.File, file, { shouldValidate: true, shouldDirty: true })
      if (file) {
        setValue(DocumentDialogFormField.Name, file.name, { shouldValidate: true, shouldDirty: true })
      }
    }

    const documentName = editedDocument?.name || 'New Document'

    return (
      <form onSubmit={submitForm}>
        <div className={cls(styles.documentsHeader, 'xt-section-border')}>
          <h3 className="xt-page-title" title={documentName}>
            {documentName}
          </h3>
          <div className={styles.documentsHeaderButtons}>
            <XtButton key="cancel" label="Cancel" onClick={onCancel} disabled={loading} />
            <XtButton type="submit" key="save" label="Save" disabled={loading || !isDirty || disabled} loading={loading} />
          </div>
        </div>
        <div className={styles.documentContent}>
          <div className={styles.formContent}>
            <TextField key="related-to" select label="Related to" value="file" variant="outlined" disabled>
              <MenuItem value="file" key="File">
                File
              </MenuItem>
            </TextField>
            <FormField disabled={disabled} control={control} name={DocumentDialogFormField.Name} label={DocumentDialogFormLabel.Name} />
            <FormField disabled={disabled} control={control} name={DocumentDialogFormField.Notes} label={DocumentDialogFormLabel.Notes} />
            <Controller
              control={control}
              name={DocumentDialogFormField.File}
              render={({ field: { value, onBlur }, fieldState: { error } }) => (
                <XtFileUpload
                  disabled={disableFileUpload || disabled}
                  file={value}
                  onChange={onFileChange}
                  error={error?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <XtCharacteristics
            disabled={disabled}
            characteristics={characteristicsState.characteristics}
            usedOnFilter={UsedOnValue.Document}
            onCreate={characteristicsState.createCharacteristic}
            onDelete={characteristicsState.deleteCharacteristic}
            onUpdate={characteristicsState.updateCharacteristic}
          />
        </div>
      </form>
    )
  }
)
