import * as React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { ReactElement } from 'react'
import * as styles from './card-list.module.scss'
import { LoadingSpinner } from '../loading-spinner'
import { XtButton } from '../buttons/xt-button/xt-button'
import { ICardListParams } from './card-list.types'
import { cls } from '../../common/utils/utils'
import { ITableRow } from '../table/table.types'
import { TableCell } from '../table/table-head/table-head.types'

export function XtCardList<TData extends ITableRow>({
  data,
  onCardClick,
  loading,
  canLoadMore,
  loadMore,
  className,
}: ICardListParams<TData>): ReactElement {
  return (
    <div className={cls(styles.cardList, className)}>
      {loading && !data.length && <LoadingSpinner />}
      <div hidden={loading || !!data.length} className={styles.noDataContainer}>
        No data available.
      </div>
      <div hidden={!data.length} className={styles.cardContentContainer}>
        {data.map((item, key1) => (
          <Card
            key={`${item.data.id}`}
            onClick={() => onCardClick && onCardClick(item.data)}
            elevation={0}
            className="xt-list-card"
            classes={{ root: item.data.selected ? 'selected' : '' }}
          >
            <CardContent>
              {item.fields.map((field, key2) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <div key={`${field.value}_${key1}_${key2}`}>
                  <span className={styles.label}>{field.label}: </span>
                  <div hidden={!field.value} className={styles.value} title={field.value?.toString() ?? ''}>
                    {field.value}
                  </div>
                  <div
                    hidden={!field.cellTemplate}
                    className={cls(field.label === TableCell.Action && styles.tableCellAction, styles.value)}
                  >
                    {field.cellTemplate}
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        ))}
      </div>
      <XtButton
        className={styles.loadMoreButton}
        label="Load more"
        hidden={!data.length || !loadMore || !canLoadMore}
        disabled={loading || !canLoadMore}
        loading={loading}
        onClick={loadMore}
      />
    </div>
  )
}
