import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { crmRoutePath } from '../crm.constants'

export class CrmAccountsGuard extends PermissionGuard {
  public redirectTo = crmRoutePath
  public permissions = [
    UserPermission.ViewAllCRMAccounts,
    UserPermission.ViewPersonalCRMAccounts,
    UserPermission.MaintainAllCRMAccounts,
    UserPermission.MaintainPersonalCRMAccounts,
  ]
}
