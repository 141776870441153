import { IUser, IUserPayload } from '../users/users.types'
import { PasswordChangeFormField, ProfileDetailsForm, ProfileDetailsFormField } from './profile.types'

export function defineFormData(user: IUser | null): ProfileDetailsForm {
  return {
    [ProfileDetailsFormField.Username]: user?.username ?? '',
    [ProfileDetailsFormField.ProperName]: user?.proper_name ?? '',
    [ProfileDetailsFormField.Initials]: user?.initials ?? '',
    [ProfileDetailsFormField.Active]: user?.status ? user.status === 'active' : true,
    [ProfileDetailsFormField.Locale]: user?.locale ? { id: user.locale, label: user.locale } : null,
    [PasswordChangeFormField.Password]: '',
    [PasswordChangeFormField.Verify]: '',
  }
}

export function convertFormData({ username, proper_name, initials, locale, password }: ProfileDetailsForm): IUserPayload {
  const payload = {
    username,
    proper_name,
    initials,
    locale: locale?.id ? locale.id : '',
  }

  return password ? { ...payload, password } : payload
}
