import * as React from 'react'
import { FC, memo, useMemo } from 'react'
import { FormField, FormSelectField, FormDatePicker } from 'common/utils/form/form.components'
import { isValidRegex } from 'common/utils/regexp.utils'
import { XtInfoPopupIcon } from 'components/info-popup-icon/info-popup-icon'
import { XtPatternValidatorPopup } from 'components/pattern-validator-popup/pattern-validator-popup'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CharacteristicDialogFormLabel, ICharacteristicDialogValue } from './characteristic-dialog.types'
import { CharacteristicType, CharacteristicDialogFormField } from '../characteristics.types'

export const CharacteristicDialogValueInput: FC<ICharacteristicDialogValue> = memo(({ type, control, options, mask, validator }) => {
  const controlMask = mask ?? undefined
  const label = CharacteristicDialogFormLabel.Value
  const name = CharacteristicDialogFormField.Value
  const items = useMemo<IXtAutocompleteOption[]>(() => options.map(({ option_value }) => defineAutocompleteOption(option_value)), [options])

  const endAdornment =
    validator && isValidRegex(validator) ? (
      <XtInfoPopupIcon title="Examples" popup={<XtPatternValidatorPopup pattern={validator} />} />
    ) : undefined

  switch (type) {
    case CharacteristicType.Text:
      return <FormField control={control} name={name} label={label} mask={controlMask} inputProps={{ endAdornment }} />
    case CharacteristicType.List:
      return <FormSelectField options={items} control={control} name={name} label={label} />
    case CharacteristicType.Date:
      return <FormDatePicker control={control} name={name} label={label} />
    case CharacteristicType.Number:
      // TODO type number
      return <FormField type="number" control={control} name={name} label={label} mask={controlMask} />
    default:
      return <FormField control={control} name={name} label={label} disabled mask={controlMask} />
  }
})
