import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { FilterValue, IFilterFormState } from 'components/filter/filter.types'
import { isAutocompleteValue } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { dateToServerFormat } from './date.utils'

type ExtractFilterValueType<T> = T extends IXtAutocompleteOption
  ? string
  : T extends Date
  ? string
  : T extends IXtAutocompleteOption[]
  ? FilterValue[]
  : T extends null
  ? undefined
  : T

type FilterType<T extends Record<string, unknown>> = { [P in keyof T]: ExtractFilterValueType<T[P]> }

function isDate(value: unknown): value is Date {
  return value instanceof Date
}

function isFilterOption(value: unknown): value is IXtAutocompleteOption {
  return typeof value === 'object' && value !== null && 'value' in value && 'label' in value
}

function isFilterOptions(value: unknown): value is IXtAutocompleteOption[] {
  return Array.isArray(value) && value.every(isFilterOption)
}

function isKey<T extends { [K in keyof T]: T[K] }>(key: unknown, filtersPanelState: T): key is keyof T {
  return typeof key === 'string' && Object.prototype.hasOwnProperty.call(filtersPanelState, key)
}

function processValue(value: IFilterFormState[keyof IFilterFormState]): ExtractFilterValueType<IFilterFormState[keyof IFilterFormState]> {
  let processedValue: ExtractFilterValueType<IFilterFormState[keyof IFilterFormState]>
  if (isAutocompleteValue(value)) {
    processedValue = value.id
  }
  if (isDate(value)) {
    processedValue = dateToServerFormat(value) ?? undefined
  }
  if (isFilterOptions(value)) {
    processedValue = value.map((option) => option.id)
  }
  if (value === null) {
    return undefined
  }
  return processedValue ?? (value as ExtractFilterValueType<IFilterFormState[keyof IFilterFormState]>)
}

export function convertFiltersPanelState<T extends IFilterFormState>(obj: T): FilterType<T> {
  const keys: Array<keyof FilterType<T>> = Object.keys(obj).filter((key) => isKey<T>(key, obj))

  return Object.values(obj).reduce((res, value, index) => {
    const key = keys[index]

    res[key] = processValue(value) as FilterType<T>[keyof FilterType<T>]

    return res
  }, {} as FilterType<T>)
}

export function checkAndCreateFiltersObject<TValue extends { [key: string]: TValue[keyof TValue] }>(filter?: TValue): Partial<TValue> {
  if (!filter) {
    return {}
  }
  const keys: Array<keyof TValue> = Object.keys(filter).filter((key) => isKey<TValue>(key, filter))
  return Object.values(filter).reduce((res, value, index) => {
    const key = keys[index]
    if (typeof value === 'number' || value) {
      res[key] = value
    }
    return res
  }, {} as TValue)
}
