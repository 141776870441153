import { IAsyncRoute } from '../common/routing/routing.types'
import { OpportunitiesGuard } from '../crm/opportunities/opportunities.guard'
import { DashboardGuard } from './dashboard.guard'
import { TasksGuard } from '../tasks/tasks.guard'
import { DashboardModule, DashboardModuleDefinition } from './dashboard.module'

export const dashboardRoutePath = '/dashboard'

export const dashboardRoutes = {
  opportunities: 'opportunities',
  tasks: 'tasks',
}

export const dashboardRoute: IAsyncRoute<DashboardModuleDefinition> = {
  path: dashboardRoutePath,
  guards: [DashboardGuard],
  module: DashboardModule,
  childrenRoutesFactory: ({ OpportunitiesBoard, TasksBoard }: DashboardModuleDefinition) => [
    {
      path: dashboardRoutes.opportunities,
      component: OpportunitiesBoard,
      guards: [OpportunitiesGuard],
      name: 'Opportunities',
    },
    {
      path: dashboardRoutes.tasks,
      component: TasksBoard,
      guards: [TasksGuard],
      name: 'Tasks',
    },
  ],
}
