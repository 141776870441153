import { ILazyModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { vendorModule, vendorServiceToken, vendorUtilsServiceToken } from './vendor.constants'
import { Injector } from '../core/injector/injector.service'
import { IVendorService } from './vendor.service'
import { httpClientToken, IHttpClient } from '../core/services/http-client'
import { Scope } from '../core/injector/injector.types'
import { IVendorUtilsService } from './vendor-utils.service'
import { CoreModule } from '../core/core.module'
import { CommentsModule } from '../comments/comments.module'
import { DocumentsModule } from '../documents/documents.module'
import { CharacteristicsModule } from '../characteristics/characteristics.module'
import { ContactsModule } from '../contacts/contacts.module'
import { ShipmentsModule } from '../shipments/shipments.module'
import { SetupUsersModule } from '../setup/users/users.module'
import { PurchaseOrdersModule } from '../purchase-orders/purchase-orders.module'
import { IDocumentsService } from '../documents/documents.types'
import { documentsServiceToken } from '../documents/documents.constants'
import { ICommentsService } from '../comments/comments.service'
import { commentsServiceToken } from '../comments/comments.constants'
import { usersServiceToken } from '../users/users.constants'
import { IUsersService } from '../users/users.service'

export type VendorModuleDefinition = typeof import('./vendor.exports')

export interface IVendorModuleServices {
  VendorService: IVendorService
  VendorUtilsService: IVendorUtilsService
}

export const VendorModule: ILazyModule<VendorModuleDefinition> = {
  name: vendorModule,
  resolver: () => import('./vendor.exports'),
  initializer: ({ VendorService, VendorUtilsService }) => {
    Injector.register<IVendorService>(
      vendorServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new VendorService(httpClient, commentsService, documentsService)
      },
      Scope.Singleton
    )
    Injector.register<IVendorUtilsService>(
      vendorUtilsServiceToken,
      (injector) => {
        const vendorService = injector.resolve<IVendorService>(vendorServiceToken)
        const userService = injector.resolve<IUsersService>(usersServiceToken)

        return new VendorUtilsService(vendorService, userService)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    CommentsModule,
    DocumentsModule,
    CharacteristicsModule,
    ContactsModule,
    ShipmentsModule,
    SetupUsersModule,
    PurchaseOrdersModule,
  ],
}
