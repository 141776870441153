// eslint-disable-next-line max-classes-per-file
import { Observable } from 'rxjs'

export interface IAuthService {
  isAuthenticated$: Observable<boolean>
  register(email: string, password: string): Promise<void>
  login(username: string, password: string): Promise<void>
  resetPassword(email: string): Promise<void>
  changePassword(password: string, token: string): Promise<void>
  logout(): void
  isValidPasswordResetToken(token: string): Promise<boolean>
  isAuthenticated(): boolean
  getCurrentUser(): IAuthUser | null
  refreshToken(): Promise<void>
  getToken(): string | null
  getUsername(): string | null
}

export interface IAuthUser {
  access_token: string
  access_token_expiration: number
  access_token_expires_in: number
  access_token_jti: string
  privileges: UserPermission[]
  refresh_token: string
  refresh_token_expiration: number
  refresh_token_expires_in: number
  refresh_token_jti: string
  username: string
}

export enum UserPermission {
  ViewItemSources = 'ViewItemSources ',
  MaintainItemSources = 'MaintainItemSources',
  MaintainPurchaseRequests = 'MaintainPurchaseRequests',
  ViewPurchaseRequests = 'ViewPurchaseRequests',
  MaintainUsers = 'MaintainUsers',
  ConvertQuotes = 'ConvertQuotes',
  ViewSalesHistory = 'ViewSalesHistory',
  CanSendSMS = 'CanSendSMS',
  CanMakeVoiceCalls = 'CanMakeVoiceCalls',
  AlterPackDate = 'AlterPackDate',
  ViewCosts = 'ViewCosts',
  OverridePrice = 'OverridePrice',
  OverrideTax = 'OverrideTax',
  ShowMarginsOnSalesOrder = 'ShowMarginsOnSalesOrder',
  CreateSOForHoldCustomer = 'CreateSOForHoldCustomer',
  CreateSOForWarnCustomer = 'CreateSOForWarnCustomer',
  OverrideSODate = 'OverrideSODate',
  OverrideSOHoldType = 'OverrideSOHoldType',
  MaintainShiptos = 'MaintainShiptos',
  MaintainCustomerMastersCustomerTypeOnCreate = 'MaintainCustomerMastersCustomerTypeOnCreate',
  MaintainCustomerMastersCustomerType = 'MaintainCustomerMastersCustomerType',
  EditOwner = 'EditOwner',
  MaintainProspectMasters = 'MaintainProspectMasters',
  ViewProspectMasters = 'ViewProspectMasters',
  MaintainQuotes = 'MaintainQuotes',
  ViewQuotes = 'ViewQuotes',
  MaintainAllTaskItems = 'MaintainAllTaskItems',
  MaintainPersonalTaskItems = 'MaintainPersonalTaskItems',
  ViewAllTaskItems = 'ViewAllTaskItems',
  ViewPersonalTaskItems = 'ViewPersonalTaskItems',
  MaintainAllContacts = 'MaintainAllContacts',
  MaintainPersonalContacts = 'MaintainPersonalContacts',
  ViewAllContacts = 'ViewAllContacts',
  ViewPersonalContacts = 'ViewPersonalContacts',
  ViewAllCRMAccounts = 'ViewAllCRMAccounts',
  ViewPersonalCRMAccounts = 'ViewPersonalCRMAccounts',
  MaintainAllCRMAccounts = 'MaintainAllCRMAccounts',
  MaintainPersonalCRMAccounts = 'MaintainPersonalCRMAccounts',
  ViewCustomerMasters = 'ViewCustomerMasters',
  MaintainCustomerMasters = 'MaintainCustomerMasters',
  ViewAllOpportunities = 'ViewAllOpportunities',
  ViewPersonalOpportunities = 'ViewPersonalOpportunities',
  MaintainAllOpportunities = 'MaintainAllOpportunities',
  MaintainPersonalOpportunities = 'MaintainPersonalOpportunities',
  ViewSalesOrders = 'ViewSalesOrders',
  MaintainSalesOrders = 'MaintainSalesOrders',
  ViewItemMasters = 'ViewItemMasters',
  MaintainItemMasters = 'MaintainItemMasters',
  ViewItemAvailabilityWorkbench = 'ViewItemAvailabilityWorkbench',
  ViewBOMs = 'ViewBOMs',
  MaintainBOMs = 'MaintainBOMs',
  ViewBOOs = 'ViewBOOs',
  MaintainBOOs = 'MaintainBOOs',
  ConfigureSO = 'ConfigureSO',
  ConfigureCRM = 'ConfigureCRM',
  ConfigurePO = 'ConfigurePO',
  MaintainCharacteristics = 'MaintainCharacteristics',
  ViewCharacteristics = 'ViewCharacteristics',
  MaintainCustomerTypes = 'MaintainCustomerTypes',
  ViewCustomerTypes = 'ViewCustomerTypes',
  MaintainSaleTypes = 'MaintainSaleTypes',
  ViewSaleTypes = 'ViewSaleTypes',
  MaintainOpportunitySources = 'MaintainOpportunitySources',
  MaintainOpportunityStages = 'MaintainOpportunityStages',
  MaintainOpportunityTypes = 'MaintainOpportunityTypes',
  MaintainClassCodes = 'MaintainClassCodes',
  ViewClassCodes = 'ViewClassCodes',
  MaintainProductCategories = 'MaintainProductCategories',
  ViewProductCategories = 'ViewProductCategories',
  MaintainUOMs = 'MaintainUOMs',
  ViewUOMs = 'ViewUOMs',
  MaintainCommentTypes = 'MaintainCommentTypes',
  ViewCRMRoles = 'ViewCRMRoles',
  MaintainCRMRoles = 'MaintainCRMRoles',
  MaintainGroups = 'MaintainGroups',
}

export class RefreshTokenError extends Error {}

export class MissedRefreshTokenError extends RefreshTokenError {
  public message: string = 'No refresh token is stored for the current user.'
}

export class FailedRefreshTokenError extends RefreshTokenError {
  public message: string = 'Failed to refresh the access token.'
}
