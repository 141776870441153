import { XtMode } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { StageTaskOption } from './tasks.types'

export const tasksRoutePath: string = '/tasks'
export const tasksCalendarRoutePath: string = '/calendar'
export const tasksModule = 'TasksModule'
export const tasksServiceToken = 'TasksService'
export const tasksUtilsServiceToken = 'TasksUtilsServiceToken'

export const defaultTaskDetailsDialogState = { id: null, mode: XtMode.New, open: false }

export const taskStatusOptions: IXtAutocompleteOption[] = Object.values(StageTaskOption).map((option) => defineAutocompleteOption(option))
