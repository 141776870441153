import * as React from 'react'
import { FC, ReactElement } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import * as styles from './navbar.module.scss'
import { IXtNavbar } from './navbar.types'

const XtNavTab: (props: React.PropsWithoutRef<NavLinkProps<never>> & { name: string }) => ReactElement = ({ name, ...props }) => (
  <Tab
    classes={{ root: styles.navContainer, textColorInherit: styles.tabTextInherit }}
    component={NavLink as never}
    label={name}
    {...(props as never)} /* eslint-disable-line react/jsx-props-no-spreading */
  />
)

export const XtNavbar: FC<IXtNavbar> = ({ options }) => (
  <Tabs variant="scrollable" scrollButtons="auto" value={false} classes={{ flexContainer: styles.tabsContainer }}>
    {options.map(({ path, name }) => (
      <XtNavTab key={path} activeClassName={styles.activeLink} to={path} name={name} />
    ))}
  </Tabs>
)
