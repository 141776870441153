import { IModule } from './react-lazy-modules/react-lazy-modules.types'
import * as CoreModuleExport from './core.exports'
import { Injector } from './injector/injector.service'
import { IDashboardService } from './services/dashboard.service'
import {
  coreModuleToken,
  coreUtilsServiceToken,
  crmRolesServiceToken,
  dashboardServiceToken,
  dictionaryServiceToken,
  errorHandlerServiceToken,
  expenseCategoryServiceToken,
  glAccountsServiceToken,
  navigationServiceToken,
  operationTypesServiceToken,
  phoneServiceToken,
  projectServiceToken,
  salesRepServiceToken,
  sitesServiceToken,
  sourceServiceToken,
  standardOperationServiceToken,
  termsServiceToken,
  uomServiceToken,
  workCenterServiceToken,
} from './core.constants'
import { Scope } from './injector/injector.types'
import { HttpClient, httpClientToken, IHttpClient } from './services/http-client'
import { IDictionaryService } from './services/dictionary.service'
import { INavigationService } from './services/navigation.service'
import { ToastModule } from '../toast/toast.module'
import { IErrorHandler } from './services/error-handler.service'
import { IToastService } from '../toast/toast.service'
import { toastServiceToken } from '../toast/toast.constants'
import { IGlAccountsService } from './services/gl-accounts.service'
import { ICoreUtilsService } from './services/utils.service'
import { ICRMRolesService } from './services/crm-role.service'
import { IUomService } from './services/uom.service'
import { IOperationTypesService } from './services/operation-types.service'
import { IWorkingCenterService } from './services/work-center.service'
import { ITermsService } from './services/terms.service'
import { ISalesRepService } from './services/sales-rep.service'
import { ISitesService } from './services/sites.service'
import { IProjectService } from './services/project.service'
import { ISourceService } from './services/source.service'
import { IStandardOperationService } from './services/standard-operation.service'
import { IToastModuleServices } from '../toast/toast-module.types'
import { IPhoneService } from './services/phone.service'
import { IExpenseCategoryService } from './services/expense-category.service'

export type CoreModuleDefinition = typeof import('./core.exports')

export interface ICoreModuleServices extends IToastModuleServices {
  DictionaryService: IDictionaryService
  DashboardService: IDashboardService
  NavigationService: INavigationService
  ErrorHandler: IErrorHandler
  GlAccountsService: IGlAccountsService
  ExpenseCategoryService: IExpenseCategoryService
  CoreUtilsService: ICoreUtilsService
  CRMRolesService: ICRMRolesService
  UomService: IUomService
  OperationTypesService: IOperationTypesService
  WorkCenterService: IWorkingCenterService
  TermsService: ITermsService
  SalesRepService: ISalesRepService
  SitesService: ISitesService
  ProjectService: IProjectService
  SourceService: ISourceService
  StandardOperationService: IStandardOperationService
  PhoneService: IPhoneService
}

function coreModuleInitializer({
  DashboardService,
  DictionaryService,
  NavigationService,
  ErrorHandler,
  GlAccountsService,
  ExpenseCategoryService,
  CoreUtilsService,
  CRMRolesService,
  WorkCenterService,
  SalesRepService,
  UomService,
  TermsService,
  SourceService,
  StandardOperationService,
  OperationTypesService,
  SitesService,
  ProjectService,
  PhoneService,
}: CoreModuleDefinition): void {
  Injector.register(httpClientToken, () => HttpClient, Scope.Singleton)

  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

  Injector.register<IPhoneService>(phoneServiceToken, () => new PhoneService(httpClient), Scope.Singleton)
  Injector.register<IDashboardService>(dashboardServiceToken, () => new DashboardService(httpClient), Scope.Singleton)
  Injector.register<IDictionaryService>(dictionaryServiceToken, () => new DictionaryService(httpClient), Scope.Singleton)
  Injector.register<INavigationService>(navigationServiceToken, () => new NavigationService(), Scope.Singleton)
  Injector.register<IGlAccountsService>(glAccountsServiceToken, () => new GlAccountsService(httpClient), Scope.Singleton)
  Injector.register<IExpenseCategoryService>(expenseCategoryServiceToken, () => new ExpenseCategoryService(httpClient), Scope.Singleton)
  Injector.register<IErrorHandler>(
    errorHandlerServiceToken,
    (injector) => {
      const toastService = injector.resolve<IToastService>(toastServiceToken)
      return new ErrorHandler(toastService)
    },
    Scope.Singleton
  )

  Injector.register<ICRMRolesService>(crmRolesServiceToken, () => new CRMRolesService(httpClient), Scope.Singleton)
  Injector.register<ISitesService>(sitesServiceToken, () => new SitesService(httpClient), Scope.Singleton)
  Injector.register<ISourceService>(sourceServiceToken, () => new SourceService(httpClient), Scope.Singleton)
  Injector.register<IUomService>(uomServiceToken, () => new UomService(httpClient), Scope.Singleton)
  Injector.register<IProjectService>(projectServiceToken, () => new ProjectService(httpClient), Scope.Singleton)
  Injector.register<ISalesRepService>(salesRepServiceToken, () => new SalesRepService(httpClient), Scope.Singleton)
  Injector.register<IWorkingCenterService>(workCenterServiceToken, () => new WorkCenterService(httpClient), Scope.Singleton)
  Injector.register<ITermsService>(termsServiceToken, () => new TermsService(httpClient), Scope.Singleton)
  Injector.register<IOperationTypesService>(operationTypesServiceToken, () => new OperationTypesService(httpClient), Scope.Singleton)
  Injector.register<IStandardOperationService>(
    standardOperationServiceToken,
    () => new StandardOperationService(httpClient),
    Scope.Singleton
  )

  Injector.register<ICoreUtilsService>(
    coreUtilsServiceToken,
    (injector) => {
      const dictionaryService = injector.resolve<IDictionaryService>(dictionaryServiceToken)
      const glAccountsService = injector.resolve<IGlAccountsService>(glAccountsServiceToken)
      const expenseCategoryService = injector.resolve<IExpenseCategoryService>(expenseCategoryServiceToken)
      const workingCenterService = injector.resolve<IWorkingCenterService>(workCenterServiceToken)
      const standardOperationService = injector.resolve<IStandardOperationService>(standardOperationServiceToken)
      const operationTypesService = injector.resolve<IOperationTypesService>(operationTypesServiceToken)
      const uomService = injector.resolve<IUomService>(uomServiceToken)
      const crmRolesService = injector.resolve<ICRMRolesService>(crmRolesServiceToken)

      return new CoreUtilsService(
        expenseCategoryService,
        glAccountsService,
        dictionaryService,
        workingCenterService,
        operationTypesService,
        standardOperationService,
        uomService,
        crmRolesService
      )
    },
    Scope.Singleton
  )
}

export const CoreModule: IModule<CoreModuleDefinition> = {
  name: coreModuleToken,
  resolver: () => CoreModuleExport,
  initializer: coreModuleInitializer,
  dependencies: [ToastModule],
}
