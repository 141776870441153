import { PermissionGuard } from '../auth/permission.guard'
import { homeRoutePath } from '../home/home.constants'
import { UserPermission } from '../auth/auth.types'
import { OpportunitiesGuard } from '../crm/opportunities/opportunities.guard'
import { TasksGuard } from '../tasks/tasks.guard'

export class DashboardGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = this.definePermissions()

  private definePermissions(): UserPermission[] {
    const opportunityGuard = new OpportunitiesGuard()
    const tasksGuard = new TasksGuard()

    return [...opportunityGuard.permissions, ...tasksGuard.permissions]
  }
}
