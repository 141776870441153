// extracted by mini-css-extract-plugin
export var xtAccordion = "xt-accordion_xtAccordion__aAwnl";
export var titleSummaryContent = "xt-accordion_titleSummaryContent__hYhd8";
export var xtAccordionInvalid = "xt-accordion_xtAccordionInvalid__3iMhe";
export var muiAccordion = "xt-accordion_muiAccordion__XVTGU";
export var muiAccordionSummary = "xt-accordion_muiAccordionSummary__1tJFE";
export var muiExpandIcon = "xt-accordion_muiExpandIcon__PjoDc";
export var muiAccordionDark = "xt-accordion_muiAccordionDark__IUN6M";
export var muiAccordionSummaryContent = "xt-accordion_muiAccordionSummaryContent__b1HZM";
export var muiAccordionSummaryExpanded = "xt-accordion_muiAccordionSummaryExpanded__fxhHM";
export var muiAccordionInvalid = "xt-accordion_muiAccordionInvalid__3aZZk";
export var muiExpandIconSize = "xt-accordion_muiExpandIconSize__ZnTsC";
export var muiAccordionDetails = "xt-accordion_muiAccordionDetails__hthP-";
export var muiAccordionMargin = "xt-accordion_muiAccordionMargin__lb5qG";