import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CommentsModule } from 'comments/comments.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { documentsServiceToken } from 'documents/documents.constants'
import { IDocumentsService } from 'documents/documents.types'
import { contactsModule, contactsServiceToken, contactsUtilsServiceToken } from './contacts.constants'
import { IContactsUtilsService } from './contacts-utils.service'
import { IContactService } from './contacts.service'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'

export type ContactsModuleDefinition = typeof import('./contacts.exports')

export interface IContactsModuleServices {
  ContactsService: IContactService
  ContactsUtilsService: IContactsUtilsService
}

export const ContactsModule: ILazyModule<ContactsModuleDefinition> = {
  name: contactsModule,
  resolver: () => import('./contacts.exports'),
  initializer: ({ ContactsService, ContactsUtilsService }) => {
    Injector.register(
      contactsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new ContactsService(httpClient, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IContactsUtilsService>(
      contactsUtilsServiceToken,
      (injector) => {
        const contactsService = injector.resolve<IContactService>(contactsServiceToken)

        return new ContactsUtilsService(contactsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule, CharacteristicsModule, CommentsModule, DocumentsModule],
}
