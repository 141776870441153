import { IPaginationData } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IGlAccountsService } from './gl-accounts.service'
import { CountryStates, CurrencyOption, IDictionaryService } from './dictionary.service'
import { IStandardOperationService } from './standard-operation.service'
import { IWorkingCenterService } from './work-center.service'
import { IOperationTypesService } from './operation-types.service'
import { InventoryUomOption, IUomService } from './uom.service'
import { ICRMRolesService, RoleAppliesto } from './crm-role.service'
import { IExpenseCategoryService } from './expense-category.service'

const defaultCountryStates = {
  initialState: '',
  initialCountry: null,
}

export interface ICoreUtilsService {
  loadCurrencyOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<CurrencyOption>>
  loadGlAccountOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadExpenseCategoryOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCountries(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadWorkCenterOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadStandardOperationOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCRMContactRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOperationTypeOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCRMPhoneRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadUomOptions(page: number, limit: number): Promise<IPaginationData<InventoryUomOption>>
  loadStates(page: number, limit: number, filter?: string, countryId?: string): Promise<IPaginationData<IXtAutocompleteOption>>
  getCountryStates(countryId?: string, stateId?: string): Promise<CountryStates>
}

export class CoreUtilsService implements ICoreUtilsService {
  constructor(
    private readonly expenseCategoryService: IExpenseCategoryService,
    private readonly glAccountsService: IGlAccountsService,
    private readonly dictionaryService: IDictionaryService,
    private readonly workCenterService: IWorkingCenterService,
    private readonly operationTypesService: IOperationTypesService,
    private readonly standardOperationService: IStandardOperationService,
    private readonly uomService: IUomService,
    private readonly crmRolesService: ICRMRolesService
  ) {
    bindAllInstanceMethods(this)
  }

  public async loadCurrencyOptions(page: number, limit: number, searchFilter: string | null): Promise<IPaginationData<CurrencyOption>> {
    const { data, total } = await this.dictionaryService.getCurrencies({ page, limit }, { currency: searchFilter })
    return {
      data: data.map((currency) => ({ ...currency, id: currency.iso_code, label: currency.iso_code })),
      total,
    }
  }

  public async loadGlAccountOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.glAccountsService.getAll({ page, limit })
    return {
      data: data.map(({ glaccount, description, type }) => ({ id: glaccount, label: `${glaccount}-${description}-${type}` })),
      total,
    }
  }

  public async loadExpenseCategoryOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.expenseCategoryService.getAll({ page, limit })
    return {
      data: data.map(({ code, description }) => ({ id: code, label: `${code}-${description}` })),
      total,
    }
  }

  public async loadStandardOperationOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.standardOperationService.getAll({ limit, page })
    return {
      total,
      data: data.map(({ number }) => ({ id: number, label: number })),
    }
  }

  public async loadWorkCenterOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.workCenterService.getAll({ limit, page })
    return {
      total,
      data: data.map(({ code }) => ({ id: code, label: code })),
    }
  }

  public async loadOperationTypeOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.operationTypesService.getAll({ limit, page })
    return {
      total,
      data: data.map(({ description, code }) => ({ id: code, label: description })),
    }
  }

  public async loadUomOptions(page: number, limit: number): Promise<IPaginationData<InventoryUomOption>> {
    const { total, data } = await this.uomService.getAll({ limit, page })
    return {
      total,
      data: data.map((value) => ({ ...value, id: value.name, label: value.name, uom_conversions: [] })),
    }
  }

  public async loadCRMContactRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.crmRolesService.getAll({ limit, page })
    const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Contact))
    return {
      total,
      data: filterData.map(({ name }) => ({ id: name, label: name })),
    }
  }

  public async loadCRMPhoneRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.crmRolesService.getAll({ limit, page })
    const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Phone))
    return {
      total,
      data: filterData.map(({ name }) => ({ id: name, label: name })),
    }
  }

  public async loadStates(
    page: number,
    limit: number,
    filter?: string,
    countryId?: string
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    if (!countryId) {
      return { data: [], total: 0 }
    }

    const { total, data } = await this.dictionaryService.getStates(countryId, { limit, page }, { state_pattern: filter })

    return {
      total,
      data: data.map(({ state_code }) => ({ id: state_code, label: state_code })),
    }
  }

  public async loadCountries(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.dictionaryService.getAll({ page, limit }, { country_pattern: filter })

    return {
      data: data.map(({ country_code, country }) => ({ id: country_code, label: country })),
      total,
    }
  }

  public async getCountryStates(countryId?: string, stateId: string = ''): Promise<CountryStates> {
    if (!countryId) {
      return defaultCountryStates
    }
    try {
      const [states, country] = await Promise.all([this.dictionaryService.getStates(countryId), this.dictionaryService.get(countryId)])
      const state = stateId ? states.data.find(({ state_code }) => state_code === stateId) : null
      return {
        initialState: state ? { id: state.state_code, label: state.state_code } : stateId,
        initialCountry: { id: country.country_code, label: country.country, hasStates: Boolean(states.data.length) },
      }
    } catch {
      return defaultCountryStates
    }
  }
}
