// eslint-disable-next-line import/no-extraneous-dependencies
import { ICalendarInfo } from 'tui-calendar'
import { MenuItem } from '@material-ui/core'
import * as React from 'react'
import { CalendarView } from './calendar.types'

export const calendarModuleToken = 'CalendarModule'

export const calendarViewOptions = Object.values(CalendarView).map((view) => (
  <MenuItem key={view} value={view} title={view}>
    {view}
  </MenuItem>
))

export const xtCalendarId = 'xt-calendar'

export const calendars: ICalendarInfo[] = [
  {
    id: xtCalendarId,
    name: 'XtCalendar',
    color: '#ffffff',
    bgColor: '#0868ac',
    dragBgColor: '#0868ac',
    borderColor: '#0868ac',
  },
]

export const calendarClassNames = {
  draggingMove: 'tui-full-calendar-dragging',
  draggingWait: 'tui-full-calendar-dragging-wait',
  columnResize: 'tui-full-calendar-resizing-x',
}
