import React from 'react'
import { CircularProgress, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

import { ISortOption, IXtTableHeadType } from './table-head.types'
import * as styles from '../table-loading-spinner.module.scss'
import { cls } from '../../../common/utils/utils'
import { definePositionStyles } from '../table.utils'
import { checkSortDirection, handleColumnHeaderClick } from './table-head.utils'

const tableHeadClasses = { root: 'xt-table-header' }
const tableCellClasses = { root: cls('xt-table-cell', 'xt-table-header-cell') }
const tableRowClasses = { root: 'xt-table-row' }

export const XtTableHead: IXtTableHeadType = ({ columns, loading, sortOptions, onSortLabelClick }) => (
  <TableHead component="div" classes={tableHeadClasses}>
    <TableRow component="div" classes={tableRowClasses}>
      {loading && <CircularProgress size={styles.spinnerSize} className={styles.loadingSpinner} />}
      {columns.map(({ id, align, width, flex, headerName, sticky, unsortable }) => {
        const sortOption = sortOptions?.get(id) as ISortOption
        return (
          <TableCell
            classes={tableCellClasses}
            title={headerName}
            component="div"
            key={id}
            align={align}
            style={{ width, flex, ...definePositionStyles(sticky) }}
            sortDirection={sortOption?.sortDirection}
          >
            <TableSortLabel
              active={Boolean(sortOption?.sortDirection)}
              direction={sortOption?.sortDirection}
              onClick={() =>
                handleColumnHeaderClick(
                  {
                    sortField: id,
                    sortDirection: checkSortDirection(sortOption),
                  },
                  onSortLabelClick
                )
              }
              disabled={unsortable || !sortOptions}
            >
              {headerName}
            </TableSortLabel>
          </TableCell>
        )
      })}
    </TableRow>
  </TableHead>
)
