export const validationMessage = {
  isRequired: 'Required.',
  alreadyUsed: 'Already in use.',
  invalidNumber: 'Invalid number.',
  invalidDate: 'Invalid date format.',
  invalidEmail: 'Please enter a valid email address.',
  invalidPhone: 'Please enter a valid phone number.',
  invalidFormat: 'Invalid format.',
  invalidPatternValidator: 'Validator should have valid Regex syntax.',
  invalidInputMask: 'Mask is invalid. Please check the mask definitions.',
  inputMaskHasConflictWithValidator: "Mask shouldn't conflict with the validator.",
  minimumDate: '01/01/1900',
  passwordMatch: 'Passwords do not match.',
  min: (length: number, scale: number = 0) => `Should be more than ${length.toFixed(scale)}.`,
  max: (length: number, scale: number = 0) => `Should be less than ${length.toFixed(scale)}.`,
  minDate: (min: string) => `Should be later than ${min}.`,
  maxDate: (max: string) => `Should be earlier than ${max}.`,
  uniqueValue: (value: string) => `The value should differ from ${value}`,
}

export const validationRegExp: {
  phone: RegExp
  email: RegExp
  fileType: RegExp
} = {
  phone: /^\+?[1-9]\d{3,14}$/,
  email: /(?!.*\.\..*)(?!.*--.*)(?!.*(\.|@|^)-.*)(?!.*-(\.|@|$).*)(?!.*(\.|@)[A-Za-z0-9-]{64,}(\.|$).*)^[A-Za-z0-9!#$%&'*+/=?^_`{|}~.-]{1,64}@[A-Za-z0-9.-]{1,255}$/,
  fileType: /(jpg|jpeg|png|gif|bmp|tiff)$/i,
}

export function validatePhone(phone?: string): boolean {
  if (!phone) {
    return false
  }
  return validationRegExp.phone.test(phone)
}

export function validateEmail(email?: string): boolean {
  if (!email) {
    return false
  }
  return validationRegExp.email.test(email)
}

export function validateFileType(type?: string): boolean {
  if (!type) {
    return false
  }
  return validationRegExp.fileType.test(type)
}

export const maxNumber = 10 ** 10
export const minDate = '1900-01-01'
