import React from 'react'
import { CircularProgress } from '@material-ui/core'

interface ILoader {
  text?: string
  className?: string
}

export function LoadingSpinner({ text = 'Loading...', className }: ILoader): JSX.Element {
  return (
    <div className={className}>
      <CircularProgress size={15} /> {text}
    </div>
  )
}
