import { useCallback, useEffect, useState } from 'react'
import { globalConstants } from 'common/constants'
import { DocumentType, IAttachedDocumentWithFile, IAttachedDocument, DocumentIdField } from 'documents/documents.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ITable, useTable } from 'common/hooks/useTable'
import { convertToUpdateType } from 'common/utils/file.utils'
import { useCoreModule } from 'core/core-module-hook'
import { useDocumentsModule } from './documents-module-hook'

export interface IDocumentsHookState {
  newDocuments: IAttachedDocumentWithFile[]
  isDirty: boolean
  loading: boolean
}

export interface IDocumentsHook extends ITable<IAttachedDocument, {}> {
  saveDocument(document: IAttachedDocumentWithFile): Promise<boolean>
  saveDocuments(documents: IAttachedDocumentWithFile[]): Promise<boolean>
  updateDocument(document: IAttachedDocument): Promise<boolean>
  deleteDocument(document: IAttachedDocument): Promise<void>
  refresh(): Promise<void>
  getUnsavedDocuments: () => IAttachedDocumentWithFile[]
  creationEnabled: boolean
  isDirty: boolean
}

const defaultDocumentsHookState = {
  newDocuments: [],
  loading: false,
  meta: { page: 1, limit: globalConstants.paginationLimit, total: 0 },
  isDirty: false,
}

export function useDocuments(
  source: DocumentType,
  sourceNumber: string | null | undefined,
  creationEnabled: boolean = true,
  documentIdField?: DocumentIdField
): IDocumentsHook {
  const { DocumentsService } = useDocumentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()

  const [state, setState] = useState<IDocumentsHookState>(defaultDocumentsHookState)

  const getArrayPage = (array: IAttachedDocument[], page: number, limit: number): IAttachedDocument[] => {
    const effectivePage = Math.max(1, page)
    return array.slice((effectivePage - 1) * limit, effectivePage * limit)
  }

  const getNewDocuments = useCallback(
    async ({ limit, page }: IPaginationParams): Promise<IPaginationData<IAttachedDocument>> => ({
      data: getArrayPage(state.newDocuments, page, limit),
      total: state.newDocuments.length,
    }),
    [state.newDocuments]
  )

  const fetchDocuments = useCallback(
    (_filters: {}, paginationParams: IPaginationParams): Promise<IPaginationData<IAttachedDocument>> =>
      sourceNumber
        ? DocumentsService.getAttachedForSource(source, sourceNumber, paginationParams, { showDetail: true })
        : getNewDocuments(paginationParams),
    [sourceNumber, DocumentsService, source, getNewDocuments]
  )

  const tableState = useTable({}, fetchDocuments)

  useEffect(() => void tableState.refresh(), [source, sourceNumber, state.newDocuments])

  const saveDocument = useCallback(
    async (document: IAttachedDocumentWithFile): Promise<boolean> => {
      if (!sourceNumber) {
        const updatedNewDocuments = [...state.newDocuments, document]
        setState((prev) => ({
          ...prev,
          newDocuments: updatedNewDocuments,
          isDirty: true,
        }))

        const data = getArrayPage(updatedNewDocuments, tableState.state.meta.limit, tableState.state.meta.total)
        tableState.setData(data, data.length)
        return true
      }

      tableState.setLoading(true)
      try {
        await DocumentsService.createFileForSource(document, source, sourceNumber, documentIdField)
        ToastService.showSuccess(`Document ${document.name} was successfully created.`)
        await tableState.refresh()
        tableState.setLoading(false)
        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        tableState.setLoading(false)
        return false
      }
    },
    [sourceNumber, tableState, state.newDocuments, DocumentsService, source]
  )

  const saveDocuments = useCallback(
    async (documents: IAttachedDocumentWithFile[]): Promise<boolean> => {
      if (!sourceNumber) {
        const updatedNewDocuments = [...state.newDocuments, ...documents]
        setState((prev) => ({
          ...prev,
          newDocuments: updatedNewDocuments,
          isDirty: true,
        }))

        const data = getArrayPage(updatedNewDocuments, tableState.state.meta.limit, tableState.state.meta.total)
        tableState.setData(data, data.length)
        return true
      }

      tableState.setLoading(true)
      try {
        await DocumentsService.createMultipleFiles(documents, source, sourceNumber, documentIdField)
        await tableState.refresh()
        tableState.setLoading(false)
        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        tableState.setLoading(false)
        return false
      }
    },
    [sourceNumber, tableState, state.newDocuments, DocumentsService, source]
  )

  const updateDocument = useCallback(
    async (document: IAttachedDocument): Promise<boolean> => {
      if (!sourceNumber) {
        const updatedNewDocuments = state.newDocuments.map((arrayItem) =>
          arrayItem.id === document.id ? { ...document, file: document.file || arrayItem.file } : arrayItem
        )
        setState((prev) => ({
          ...prev,
          newDocuments: updatedNewDocuments,
          isDirty: true,
        }))
        const data = getArrayPage(updatedNewDocuments, tableState.state.meta.limit, tableState.state.meta.total)
        tableState.setData(data, data.length)

        return true
      }

      tableState.setLoading(true)
      try {
        const updatedDocument = convertToUpdateType(document)
        await DocumentsService.update(updatedDocument)
        ToastService.showSuccess(`Document ${document.name} was successfully updated.`)
        await tableState.refresh()
        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        tableState.setLoading(false)
        return false
      }
    },
    [sourceNumber, tableState, state.newDocuments, DocumentsService]
  )

  const deleteDocument = useCallback(
    async (document: IAttachedDocument): Promise<void> => {
      if (!sourceNumber) {
        const updatedNewDocuments = state.newDocuments.filter((iteratedDocument) => iteratedDocument.id !== document.id)
        setState((prevState) => ({
          ...prevState,
          newDocuments: updatedNewDocuments,
          isDirty: updatedNewDocuments.length > 0, // TODO: improve isDirty handling
        }))
        const data = getArrayPage(updatedNewDocuments, tableState.state.meta.limit, tableState.state.meta.total)
        tableState.setData(data, data.length)
      } else {
        tableState.setLoading(true)
        try {
          await DocumentsService.detach({ assignments: [{ target_type: document.target_type, id: document.id }] })
          await tableState.refresh()
        } catch (e) {
          ErrorHandler.handleError(e)
          tableState.setLoading(false)
        }
      }
    },
    [sourceNumber, state.newDocuments, tableState, DocumentsService]
  )

  const getUnsavedDocuments = useCallback(() => state.newDocuments as IAttachedDocumentWithFile[], [state.newDocuments])

  return {
    ...tableState,
    state: {
      ...tableState.state,
      loading: tableState.state.loading || state.loading,
    },
    saveDocument,
    saveDocuments,
    updateDocument,
    deleteDocument,
    creationEnabled,
    isDirty: state.isDirty,
    getUnsavedDocuments,
  }
}
