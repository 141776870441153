import { useEffect, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'

export interface IXtSelectHook<Option extends IXtAutocompleteOption, TFilters> {
  options: Option[]
  getFirstAvailableValue(): string | null
  filter(filters: TFilters): Promise<Option[]>
}

export type XtSelectRequestFn<Option extends IXtAutocompleteOption, TFilters> = (filters?: TFilters) => Promise<Option[]>

export function useXtSelect<Option extends IXtAutocompleteOption, TFilters>(
  requestFn: XtSelectRequestFn<Option, TFilters>,
  initialFilters?: TFilters
): IXtSelectHook<Option, TFilters> {
  const { ErrorHandler } = useCoreModule()

  const [options, setOptions] = useState<Option[]>([])

  const requestOptions: (filters?: TFilters) => Promise<Option[]> = async (filters) => {
    try {
      const data = await requestFn(filters)
      setOptions(data)
      return data
    } catch (e) {
      ErrorHandler.handleError(e)
      setOptions([])
      return []
    }
  }

  const getFirstAvailableValue: () => string | null = () => options[0]?.id ?? null

  useEffect(() => {
    void requestOptions(initialFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filter: requestOptions,
    options,
    getFirstAvailableValue,
  }
}
