import { useEffect, useMemo, useRef, useState } from 'react'
import { Observable, Subject } from 'rxjs'
import { useCoreModule } from 'core/core-module-hook'
import { IObjectWithId, IPaginationData, IPaginationParams } from '../common.types'
import { globalConstants } from '../constants'
import { ITablePagination } from '../types/table.types'
import { ISortOption, TableSortingFn, TSortOptions } from '../../components/table/table-head/table-head.types'

interface IFiltersState<TFilters extends {}> {
  filters: TFilters
}

type TPaginationRequestFn<TData, TFilters extends {}> = (
  filters: IFiltersState<TFilters>['filters'],
  pagination: IPaginationParams,
  sorting: ISortOption[],
  data?: TData[]
) => Promise<IPaginationData<TData>>

export interface ITableMeta extends IPaginationParams {
  total: number
}

const defaultMeta: ITableMeta = {
  page: 0,
  limit: globalConstants.paginationLimit,
  total: 0,
}

export interface ITableState<TData extends IObjectWithId, TFilters extends {}> {
  data: TData[]
  loading: boolean
  meta: ITableMeta
  filters: TFilters
  sortOptions: TSortOptions
}

export interface ITable<TData extends IObjectWithId, TFilters extends {}> {
  state: ITableState<TData, TFilters>
  filter(filters: TFilters): Promise<void>
  sort: TableSortingFn
  refresh(): Promise<void>
  setLoading(loading: boolean): void
  pagination: ITablePagination
  setData(data: TData[], total: number): void
  onChange$: Observable<TData[]>
}

export type TableRequestFn<TData, TFilters> = TPaginationRequestFn<TData, TFilters>

export function useTable<TData extends IObjectWithId, TFilters extends {}>(
  initialFilters: TFilters,
  requestFn: TableRequestFn<TData, TFilters>,
  defaultLimit: number = globalConstants.paginationLimit,
  throwError: boolean = false
): ITable<TData, TFilters> {
  const { ErrorHandler } = useCoreModule()

  const changeSubject = useRef<Subject<TData[]>>(new Subject<TData[]>())

  const [state, setState] = useState<ITableState<TData, TFilters>>({
    filters: initialFilters,
    meta: { ...defaultMeta, limit: defaultLimit },
    data: [],
    loading: false,
    sortOptions: new Map(),
  })

  function handleError(error: Error): void {
    ErrorHandler.handleError(error)
    if (throwError) {
      throw error
    }
  }

  async function requestData(
    data: TData[],
    filterParams?: TFilters | null,
    paginationParams?: IPaginationParams,
    sortingOptions?: TSortOptions
  ): Promise<TData[]> {
    try {
      const pagination = paginationParams ?? { page: state.meta.page, limit: state.meta.limit }
      const filters = filterParams ?? state.filters
      const sortOptions = sortingOptions ?? state.sortOptions
      const sortOptionsData = Array.from(sortOptions.values())

      setState((prevState) => ({
        ...prevState,
        filters,
        loading: true,
        meta: {
          limit: pagination.limit,
          total: prevState.meta.total,
          page: pagination.page,
        },
      }))
      // we keep zero-based index to work with Material UI table, but the server counts pages from 1
      const serverPagination = { ...pagination, page: pagination.page + 1 }
      const { data: newData, total } = await requestFn(filters, serverPagination, sortOptionsData, state.data)
      const tableData = [...data, ...newData]
      setState((prevState) => ({
        ...prevState,
        sortOptions,
        loading: false,
        data: tableData,
        meta: {
          ...prevState.meta,
          total,
        },
      }))
      changeSubject.current.next(tableData)
      return data
    } catch (e) {
      setState((prevState) => ({ ...prevState, loading: false }))
      handleError(e)
      return []
    }
  }

  function canLoadMore(page: number): boolean {
    return page * state.meta.limit <= state.meta.total
  }

  async function loadMore(page: number): Promise<void> {
    if (canLoadMore(page)) {
      await requestData(state.data, state.filters, { page, limit: state.meta.limit })
    }
  }

  async function changePage(page: number): Promise<void> {
    if (canLoadMore(page)) {
      await requestData([], state.filters, { page, limit: state.meta.limit })
    }
  }

  async function changeLimit(limit: number): Promise<void> {
    await requestData([], state.filters, { limit, page: 0 })
  }

  // TODO optimize calls via debounce
  async function filter(filters: TFilters): Promise<void> {
    await requestData([], filters, { limit: state.meta.limit, page: 0 })
  }

  // TODO wrap in useCallback and think about the dependencies because the current implementation depends on the table state, so refresh function link changes a lot
  async function refresh(): Promise<void> {
    await requestData([])
  }

  async function sort(sortOption: ISortOption): Promise<void> {
    const updatedSortOptions = new Map(state.sortOptions)
    const { sortField, sortDirection } = sortOption
    if (sortDirection) {
      updatedSortOptions.set(sortField, sortOption)
    } else {
      updatedSortOptions.delete(sortField)
    }
    const pagination = { page: 0, limit: state.meta.limit }

    await requestData([], state.filters, pagination, updatedSortOptions)
  }

  function setLoading(loading: boolean): void {
    setState((prevState) => ({ ...prevState, loading }))
  }

  function setData(data: TData[], total: number): void {
    setState((prevState) => ({ ...prevState, data, meta: { ...prevState.meta, total } }))
    changeSubject.current.next(data)
  }

  /**
   * Data initialization
   */
  useEffect(() => void requestData([]), [])

  const pagination: ITablePagination = {
    count: state.meta.total,
    rowsPerPage: state.meta.limit,
    page: state.meta.page,
    onChangePage: changePage,
    onChangeRowsPerPage: changeLimit,
    loadMore,
    canLoadMore: canLoadMore(state.meta.page + 1),
  }

  return useMemo(
    () => ({
      filter,
      sort,
      refresh,
      setLoading,
      setData,
      state,
      pagination,
      onChange$: changeSubject.current.asObservable(),
    }),
    [state, requestFn]
  )
}
