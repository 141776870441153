import React, { FC, memo, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import * as styles from './characteristic-dialog.module.scss'
import { CharacteristicDialogFormSchema } from './characteristic-dialog.validation'
import {
  ICharacteristicDialog,
  CharacteristicDialogFormLabel,
  ICharacteristicOption,
  ICharacteristicFormValues,
} from './characteristic-dialog.types'
import { CharacteristicDialogValueInput } from './characteristic-dialog.value-input'
import { convertCharacteristicFormValues, characteristicFormValues, defaultCharacteristicFormValues } from './characteristic-dialog.utils'
import { CharacteristicDialogFormField, ICharacteristicFormState } from '../characteristics.types'
import { useCharacteristicsModule } from '../characteristics-module-hook'

export const CharacteristicDialog: FC<ICharacteristicDialog> = memo(({ onClose, onConfirm, usedOnFilter, characteristicState }) => {
  const { CharacteristicsUtilsService } = useCharacteristicsModule()

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<ICharacteristicFormValues>({
    defaultValues: defaultCharacteristicFormValues,
    resolver: yupResolver(CharacteristicDialogFormSchema),
    mode: 'onBlur',
  })

  const characteristic = watch(CharacteristicDialogFormField.Characteristic)

  const onSaveForm = (formData: ICharacteristicFormState): void => {
    onConfirm(convertCharacteristicFormValues(formData))
    reset(defaultCharacteristicFormValues)
  }

  useEffect(() => void reset(characteristicFormValues(characteristicState)), [characteristicState])

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm)
    void eventHandler(e)
  }

  const onCancel = (): void => {
    reset(defaultCharacteristicFormValues)
    onClose()
  }

  const loadCharacteristics = useCallback(
    (page, limit) => CharacteristicsUtilsService.loadCharacteristicOptions(page, limit, usedOnFilter),
    [usedOnFilter]
  )

  const onCharacteristicChange = useCallback((characteristicOption: ICharacteristicOption) => {
    reset({ [CharacteristicDialogFormField.Characteristic]: characteristicOption, [CharacteristicDialogFormField.Value]: '' })
  }, [])

  return (
    <form onSubmit={submitForm} className={styles.characteristicDialog}>
      <FormXtAutocomplete
        control={control}
        name={CharacteristicDialogFormField.Characteristic}
        label={CharacteristicDialogFormLabel.Characteristic}
        loadOptions={loadCharacteristics}
        onChange={onCharacteristicChange}
      />
      <CharacteristicDialogValueInput
        mask={characteristic?.mask}
        control={control}
        options={characteristic?.list_options ?? []}
        type={characteristic?.characteristic_type}
        validator={characteristic?.characteristic_validator}
      />
      <div className={styles.characteristicDialogButtons}>
        <XtButton label="Cancel" onClick={onCancel} />
        <XtButton type="submit" label="Save" disabled={!isDirty} />
      </div>
    </form>
  )
})
