import { PermissionGuard } from '../auth/permission.guard'
import { homeRoutePath } from '../home/home.constants'
import { UserPermission } from '../auth/auth.types'

export class CRMGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewAllCRMAccounts,
    UserPermission.ViewPersonalCRMAccounts,
    UserPermission.MaintainAllCRMAccounts,
    UserPermission.MaintainPersonalCRMAccounts,
    UserPermission.ViewCustomerMasters,
    UserPermission.MaintainCustomerMasters,
    UserPermission.ViewProspectMasters,
    UserPermission.MaintainProspectMasters,
    UserPermission.ViewAllOpportunities,
    UserPermission.ViewPersonalOpportunities,
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
    UserPermission.ViewAllContacts,
    UserPermission.ViewPersonalContacts,
    UserPermission.MaintainAllContacts,
    UserPermission.MaintainPersonalContacts,
  ]
}
