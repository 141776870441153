import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { CoreModule } from 'core/core.module'
import { IShippingService } from './shipping.service'
import { shippingModule, shippingServiceToken, shippingUtilsServiceToken } from './shipping.constants'
import { IShippingUtilsService } from './shipping-utils.service'

export type ShippingModuleDefinition = typeof import('./shipping.exports')

export interface IShippingModuleService {
  ShippingService: IShippingService
  ShippingUtilsService: IShippingUtilsService
}

export const ShippingModule: ILazyModule<ShippingModuleDefinition> = {
  name: shippingModule,
  resolver: () => import('./shipping.exports'),
  initializer: ({ ShippingService, ShippingUtilsService }) => {
    Injector.register<IShippingService>(
      shippingServiceToken,
      (injector) => new ShippingService(injector.resolve<IHttpClient>(httpClientToken)),
      Scope.Singleton
    )
    Injector.register<IShippingUtilsService>(
      shippingUtilsServiceToken,
      (injector) => {
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const shippingService = injector.resolve<IShippingService>(shippingServiceToken)

        return new ShippingUtilsService(documentsService, shippingService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule],
}
