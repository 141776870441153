import { XtAutocompleteLoadOptionsFunc } from '../components/controls/xt-autocomplete/xt-autocomplete.types'
import { ICharacteristicService } from './characteristics.sevice'
import { bindAllInstanceMethods } from '../common/utils/object.utils'

export interface ICharacteristicsUtilsService {
  loadCharacteristicOptions: XtAutocompleteLoadOptionsFunc
}

export class CharacteristicsUtilsService implements ICharacteristicsUtilsService {
  constructor(private readonly characteristicsService: ICharacteristicService) {
    bindAllInstanceMethods(this)
  }

  public loadCharacteristicOptions: XtAutocompleteLoadOptionsFunc = async (page, limit, filter: string | null) => {
    const { total, data } = await this.characteristicsService.getAll({ limit, page }, filter)
    return {
      total,
      data: data.map((item) => ({ ...item, id: item.name, label: item.name })),
    }
  }
}
