import { IAsyncRoute } from 'common/routing/routing.types'
import { ShippingModule, ShippingModuleDefinition } from './shipping.module'
import { shippingRoutePath, shippingRoutePaths } from './shipping.constants'

export const shippingRoute: IAsyncRoute<ShippingModuleDefinition> = {
  path: shippingRoutePath,
  name: 'Shipping',
  module: ShippingModule,
  childrenRoutesFactory: ({ RecallOrdersToShipping }) => [
    {
      path: shippingRoutePaths.recallOrders,
      name: 'Recall Orders',
      exact: true,
      children: [{ path: '', exact: true, component: RecallOrdersToShipping }],
    },
  ],
}
