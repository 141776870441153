import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DocumentType } from 'documents/documents.types'
import { XtQuickAddButton } from 'components/buttons/xt-quick-add-button/xt-quick-add-button'
import { cls } from 'common/utils/utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { LoadingSpinner } from 'components/loading-spinner'
import { useCoreModule } from 'core/core-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { useCommentsModule } from '../comments-module-hook'
import { CommentItem, CommentItemInput } from '../comment/comment.types'
import { XtComment } from '../comment/comment'
import * as styles from './comments.module.scss'

export interface IXtCommentsPaginationParams {
  canLoadMore(): boolean
  loadMore(): Promise<void>
  total: number
}

export interface IXtCommentsParams extends IXtCommentsPaginationParams {
  username?: string
  comments: CommentItem[]
  hidden?: boolean
  creationEnabled?: boolean
  disabled?: boolean
  onUpdate: (comments: CommentItem) => void | Promise<void>
  onAdd: (comment: CommentItem) => boolean | Promise<boolean>
  className?: string
  source: DocumentType
}

export interface IXtCommentsState {
  newComment: CommentItemInput | null
}

const createNewCommentPlaceholder: (username?: string) => CommentItemInput = (username) => ({
  id: new Date().getTime(),
  created: new Date().toISOString(),
  comment: '',
  comment_type: null,
  comment_source: '',
  source: DocumentType.Item,
  username: username ?? '',
  editable: true,
})

export const XtComments: FC<IXtCommentsParams> = React.memo(
  ({
    comments,
    username,
    onUpdate,
    onAdd,
    hidden = false,
    disabled = false,
    creationEnabled = false,
    canLoadMore,
    loadMore,
    className,
    source,
  }) => {
    const { CommentsService } = useCommentsModule()
    const { ErrorHandler } = useCoreModule()

    const [state, setState] = useState<IXtCommentsState>({ newComment: null })

    const addNewComment = useCallback<() => void>(() => {
      setState((prev) => ({ ...prev, newComment: createNewCommentPlaceholder(username) }))
    }, [username])

    const loadCommentTypes: () => Promise<IXtAutocompleteOption[]> = async () => {
      try {
        const commentTypes = await CommentsService.getCommentTypesForSource(source)
        return commentTypes.map(({ name }) => defineAutocompleteOption(name))
      } catch (e) {
        ErrorHandler.handleError(e)
        return []
      }
    }

    const { options: commentTypes } = useXtSelect(loadCommentTypes)

    const saveNewComment = useCallback<(comment: CommentItem) => void | Promise<void>>(
      async (comment) => {
        if (typeof onAdd !== 'function') {
          throw new Error('XtComments: onAdd callback should be a function.')
        }
        const saved = await onAdd(comment)
        // After the comment is saved, "comments" param should be updated to add the created comment in the list. So, we should remove the placeholder from the list.
        if (saved) {
          setState((prev) => ({ ...prev, newComment: null }))
        }
      },
      [onAdd]
    )

    const onCommentUpdate = useCallback<(comment: CommentItem) => void | Promise<void>>(
      async (comment) => {
        if (typeof onUpdate !== 'function') {
          throw new Error('XtComments: onChange callback should be a function.')
        }
        await onUpdate(comment)
      },
      [onUpdate]
    )

    const handleNewCommentCancel: VoidFunction = () => setState((prev) => ({ ...prev, newComment: null }))

    return (
      <div hidden={hidden} className={cls(styles.xtComments, className)} id="xt-comments-scrollable">
        {commentTypes && (
          <InfiniteScroll
            scrollableTarget="xt-comments-scrollable"
            className={styles.xtCommentsList}
            next={loadMore}
            hasMore={canLoadMore()}
            dataLength={comments.length}
            loader={<LoadingSpinner />}
          >
            {state.newComment && (
              <XtComment
                isNewComment
                options={commentTypes}
                key={String(state.newComment.id)}
                comment={state.newComment}
                onUpdate={saveNewComment}
                onNewCommentCancel={handleNewCommentCancel}
              />
            )}
            {comments.map(
              (comment) =>
                commentTypes && (
                  <XtComment
                    options={commentTypes}
                    disabled={disabled || username !== comment.username || !comment.editable}
                    key={String(comment.id)}
                    comment={comment}
                    onUpdate={onCommentUpdate}
                  />
                )
            )}
          </InfiniteScroll>
        )}
        <div className={styles.stickyWrapper} hidden={disabled || !creationEnabled}>
          <XtQuickAddButton className={styles.xtCommentsAddButton} disabled={!!state.newComment} onClick={addNewComment} />
        </div>
      </div>
    )
  }
)
