import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { commentsServiceToken } from 'comments/comments.constants'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { ICommentsService } from 'comments/comments.service'
import { IDocumentsService } from 'documents/documents.types'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CommentsModule } from 'comments/comments.module'
import { DocumentsModule } from 'documents/documents.module'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { ITasksUtilsService } from './tasks-utils.service'
import { tasksModule, tasksServiceToken, tasksUtilsServiceToken } from './tasks.constants'
import { ITaskService } from './tasks.service'
import { CalendarModule } from '../calendar/calendar.module'

export type TasksModuleDefinition = typeof import('./tasks.exports')

export interface ITasksModuleServices {
  TasksUtilsService: ITasksUtilsService
  TasksService: ITaskService
}

export const TasksModule: ILazyModule<TasksModuleDefinition> = {
  name: tasksModule,
  resolver: () => import('./tasks.exports'),
  initializer: ({ TasksService, TasksUtilsService }) => {
    Injector.register<ITaskService>(
      tasksServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new TasksService(httpClient, commentsService, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<ITasksUtilsService>(
      tasksUtilsServiceToken,
      (injector) => {
        const taskService = injector.resolve<ITaskService>(tasksServiceToken)
        const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        return new TasksUtilsService(taskService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, CalendarModule, AuthModule, CharacteristicsModule, DocumentsModule, CommentsModule],
}
