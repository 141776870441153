import React from 'react'
import { MenuItem } from '@material-ui/core'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'

export function renderSelectOption({ id, label, disabled }: IXtAutocompleteOption): React.ReactElement {
  return (
    <MenuItem key={id} value={id} title={label} disabled={disabled}>
      {label}
    </MenuItem>
  )
}

export function renderSelectOptionWithIdOnly({ id, disabled }: IXtAutocompleteOption): React.ReactElement {
  return (
    <MenuItem key={id} value={id} title={id} disabled={disabled}>
      {id}
    </MenuItem>
  )
}
