import { Control } from 'react-hook-form'
import { Overwrite } from 'utility-types'
import { UsedOnValue } from 'documents/documents.types'
import { IXtAutocompleteOption } from '../../components/controls/xt-autocomplete/xt-autocomplete.types'
import {
  CharacteristicType,
  ICharacteristic,
  ICharacteristicListOption,
  ICharacteristicFormState,
  CharacteristicDialogFormField,
  IAppliedCharacteristicNew,
} from '../characteristics.types'

export enum CharacteristicDialogFormLabel {
  Characteristic = 'Characteristic',
  Value = 'Value',
}

export type ICharacteristicOption = (ICharacteristic & IXtAutocompleteOption) | null

export interface ICharacteristicDialog {
  onClose(): void
  onConfirm(formData: IAppliedCharacteristicNew): void
  usedOnFilter: UsedOnValue
  characteristicState: ICharacteristicFormState | null
}

export interface ICharacteristicDialogValue {
  type?: CharacteristicType
  control: Control<ICharacteristicFormValues>
  options: ICharacteristicListOption[]
  mask?: string | null
  validator?: string | null
}

export interface ICharacteristicFormValues
  extends Overwrite<ICharacteristicFormState, { [CharacteristicDialogFormField.Characteristic]: ICharacteristicOption }> {}
