import React, { FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Subject } from 'rxjs'
import { delay, distinctUntilChanged, filter, tap } from 'rxjs/operators'
import { Fade, Modal, Slide } from '@material-ui/core'
import { globalConstants } from 'common/constants'
import * as styles from './xt-dialog.module.scss'
import { cls } from '../../common/utils/utils'

export enum XtDialogAnimation {
  SlideAnimation,
  FadeAnimation,
}

export interface IXtDialogParams {
  open: boolean
  animation: XtDialogAnimation
  className?: string
}

function buildDialog(open: boolean, animation: XtDialogAnimation, children: ReactNode, className?: string): ReactElement | null {
  const content = <div className={cls(styles.xtDialogContainer, className)}>{children}</div>

  if (animation === XtDialogAnimation.SlideAnimation) {
    return (
      <Modal open={open}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          {content}
        </Slide>
      </Modal>
    )
  }
  return (
    <Modal open={open}>
      <Fade timeout={globalConstants.dialogAnimationFadeTime} in={open}>
        {content}
      </Fade>
    </Modal>
  )
}

// TODO make children required
export const XtDialog: FC<IXtDialogParams> = ({ open, animation, children, className }) => {
  const [dialogExists, setDialogExists] = useState<boolean>(false)

  const openStateSubject = useRef<Subject<boolean>>(new Subject<boolean>())

  useEffect(() => {
    openStateSubject.current.next(open)
  }, [open])

  useEffect(() => {
    const sub = openStateSubject.current
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap((isOpen) => {
          if (isOpen) {
            setDialogExists(isOpen)
          }
        }),
        filter((isOpen) => !isOpen),
        delay(globalConstants.dialogAnimationFadeTime)
      )
      .subscribe((closed) => setDialogExists(closed))

    return () => sub.unsubscribe()
  }, [])

  return dialogExists ? buildDialog(open, animation, children, className) : null
}
