import { parse as fnsParseDate, format as fnsFormatDate, parseISO } from 'date-fns'
import * as yup from 'yup'
import { globalConstants } from '../constants'
import { TableValueConverter } from '../../components/table/table-head/table-head.types'
import { minDate, validationMessage } from '../validation/validation'

export const parseDate = (date: string, format: string): Date => fnsParseDate(date, format, new Date())
export const formatDate = (date: Date, format: string): string => fnsFormatDate(date, format)

export function isValidDate(date: Date): boolean {
  return !Number.isNaN(date.getTime())
}

/**
 * Parses data in specific server format like 2021-11-11 to the Date object without Timezone information.
 * @param date - date to parse
 */
export function parseServerDate(date: string): Date
/**
 * Parses data in specific server format like 2021-11-11 to the Date object without Timezone information.
 * @param date - date to parse
 * @return {Date | null} Returns null if date is not defined
 */
export function parseServerDate(date: string | null | undefined): Date | null

export function parseServerDate(date: string | null | undefined): Date | null {
  if (typeof date !== 'string') {
    return null
  }
  const parsedDate = parseDate(date, globalConstants.serverDateFormat)
  return isValidDate(parsedDate) ? parsedDate : parseISO(date)
}

export function parseServerDateWithDefault(date: string | undefined | null, defaultDate: Date = new Date()): Date {
  return date ? parseServerDate(date) : defaultDate
}

export function parseServerDateWithStringValue(date: string | undefined | null): Date | null {
  const parsedDate = parseServerDate(date)
  return parsedDate && isValidDate(parsedDate) ? parsedDate : null
}
/**
 * Converts date to string representation using globalConstants.dateFormat
 * @param date
 */
export function convertDate(date: Date | string | null): string {
  if (!date) {
    return ''
  }

  if (date instanceof Date) {
    return formatDate(date, globalConstants.dateFormat)
  }

  const dateObject = parseServerDate(date)

  return isValidDate(dateObject) ? formatDate(dateObject, globalConstants.dateFormat) : ''
}

export function dateToServerFormat(date: Date | null, defaultValue: string): string
export function dateToServerFormat(date: Date | null, defaultValue?: string): string | null

export function dateToServerFormat(date: Date | null, defaultValue?: string): string | null {
  if (!date || !isValidDate(date)) {
    return defaultValue ?? null
  }
  return formatDate(date, globalConstants.serverDateFormat)
}

export function getCurrentDateInServerFormat(): string {
  return formatDate(new Date(), globalConstants.serverDateFormat)
}

export const dateConverter: TableValueConverter = (date: unknown) => {
  if (!date || typeof date !== 'string') {
    return ''
  }
  return convertDate(date)
}

// TODO: if there are more yup utilities, move to yup.utils
export const dateYupValidator = yup
  .date()
  .nullable()
  .typeError(validationMessage.invalidDate)
  .min(minDate, validationMessage.minDate(validationMessage.minimumDate))
