import { DeepPartial, get, set, UnpackNestedValue, useForm, UseFormReset } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import { DefaultValues, KeepStateOptions, UseFormTrigger } from 'react-hook-form/dist/types/form'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { FieldPath } from 'react-hook-form/dist/types/utils'
import * as Yup from 'yup'
import { Resolver } from 'react-hook-form/dist/types/resolvers'
import { ValidationError } from 'yup'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { buildFormStateObservable } from './form.utils'
import { IFormHook, IFormProperties, IFormStateBase, IFormStateChanges, IXtFormControl } from './form.types'

function resolveErrors<TFormValues extends FieldValues>(errors: ValidationError[]): FieldErrors<TFormValues> {
  return errors.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: { type: currentError.type ?? 'validation', message: currentError.message },
    }),
    {}
  )
}

function validationResolver<TFormValues extends FieldValues>(
  validationSchema: Yup.ObjectSchema,
  disabledRef: MutableRefObject<boolean>
): Resolver<TFormValues> {
  return async (data) => {
    try {
      const disabled = disabledRef.current

      if (disabled) {
        return { values: {}, errors: {} }
      }

      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })

      return {
        values: values ?? {},
        errors: {},
      }
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        throw new Error('Invalid Yup error.')
      }
      return {
        values: {},
        errors: resolveErrors(error.inner),
      }
    }
  }
}

export function useXtForm<TFieldValues extends FieldValues>({
  mode,
  defaultValues,
  validationSchema,
}: IFormProperties<TFieldValues>): IFormHook<TFieldValues> {
  const disabledRef = useRef<boolean>(false)
  const errorsSubjectRef = useRef<Subject<FieldErrors<TFieldValues>>>(new Subject())

  const methods = useForm<TFieldValues>({
    mode,
    defaultValues: defaultValues as UnpackNestedValue<DeepPartial<TFieldValues>>,
    resolver: validationSchema ? validationResolver(validationSchema, disabledRef) : undefined,
  })

  const { formState, watch, trigger: formTrigger, reset: formReset, getValues, control: formControl } = methods

  const formStateSubject = useRef<Subject<IFormStateBase>>(new Subject<IFormStateBase>())
  const touchedSubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))
  const fieldValidatorsShownSubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))
  const formValueSubject = useRef<Subject<TFieldValues>>(new Subject<TFieldValues>())

  const formChanges$ = useMemo<Observable<IFormStateChanges<TFieldValues>>>(
    () =>
      buildFormStateObservable(
        formStateSubject.current,
        touchedSubject.current,
        formValueSubject.current,
        fieldValidatorsShownSubject.current
      ),
    []
  )

  /**
   * Used to convert React Hook Form internal state subject to RxJs Subject so we can subscribe to "isValid" state changes for a specific control
   */
  useEffect(() => {
    const sub = formControl.formStateSubjectRef.current.subscribe({
      next: ({ errors }) => {
        if (errors !== undefined) {
          errorsSubjectRef.current.next(errors)
        }
      },
    })

    return () => sub.unsubscribe()
  }, [formControl.formStateSubjectRef])

  useEffect(() => {
    const { isDirty, isValid: isValidState, touchedFields, errors } = formState
    const isValid = disabledRef.current || isValidState // TODO find a better way to handle controls validation if controls is disabled

    formStateSubject.current.next({ isDirty, isValid })

    if (Object.keys(touchedFields).length) {
      touchedSubject.current.next(true)
      fieldValidatorsShownSubject.current.next(Boolean(Object.keys(errors).length))
    }
  }, [formState])

  useEffect(() => {
    const watchSub = watch((value) => {
      formValueSubject.current.next(value as TFieldValues)
    })

    return () => {
      watchSub.unsubscribe()
    }
  }, [watch])

  const trigger = useCallback<UseFormTrigger<TFieldValues>>(
    async (name?: FieldPath<TFieldValues> | FieldPath<TFieldValues>[]) => {
      touchedSubject.current.next(true) // we should mark the controls as touched
      const isValidForm = await formTrigger(name)

      if (!isValidForm) {
        fieldValidatorsShownSubject.current.next(true)
        // We should mark invalid controls as Touched in order to display validation
        const { errors } = formControl.formStateRef.current
        Object.keys(errors).forEach((path) => {
          set(formControl.formStateRef.current.touchedFields, path, true)
        })
        formControl.formStateSubjectRef.current.next({
          ...formControl.formStateRef.current,
          touchedFields: formControl.formStateRef.current.touchedFields,
        })
      }

      return isValidForm
    },
    [formTrigger, formControl.formStateRef, formControl.formStateSubjectRef]
  )

  const reset = useCallback<UseFormReset<TFieldValues>>(
    (values?: DefaultValues<TFieldValues>, keepStateOptions?: KeepStateOptions) => {
      if (!keepStateOptions?.keepTouched) {
        touchedSubject.current.next(false) // we should mark the controls as untouched
      }
      formReset(values, keepStateOptions)
      void formTrigger()
    },
    [formReset, formTrigger]
  )

  const markAsTouched = useCallback<VoidFunction>(() => touchedSubject.current.next(true), [])

  // TODO we should handle disabled state changes for every control in the controls, so we can remove a control from the validation schema.
  const setDisabled = useCallback<(disabled: boolean) => void>((disabled) => {
    disabledRef.current = disabled
  }, [])

  const validate = useCallback<(path: FieldPath<TFieldValues>) => Promise<boolean>>(
    async (path) => {
      const resolver = validationSchema ? validationResolver(validationSchema, disabledRef) : null
      const fieldPath = path.toString()
      const field = formControl.fieldsRef.current[fieldPath]
      if (!resolver || !field) {
        return false
      }
      const controlValue = getValues(path)
      const value = { path: controlValue }
      const fields = { [fieldPath]: field._f }
      const errors = await resolver(value, undefined, { fields })
      const error = get(errors, path)
      return !error
    },
    [validationSchema, formControl.fieldsRef, getValues]
  )

  const control = useMemo<IXtFormControl<TFieldValues>>(
    () => ({
      ...formControl,
      validate,
      subscribeToStateChanges: (name) =>
        errorsSubjectRef.current.asObservable().pipe(
          map((errors) => ({ isValid: !Object.prototype.hasOwnProperty.call(errors, name) })),
          distinctUntilChanged()
        ),
      getValue: (path) => getValues(path),
    }),
    [formControl, validate, getValues]
  )

  return {
    ...methods,
    control,
    trigger,
    reset,
    formState: { ...formState, touched: touchedSubject.current.value, fieldValidatorsShown: fieldValidatorsShownSubject.current.value },
    markAsTouched,
    formChanges$,
    setDisabled,
  }
}
