import * as React from 'react'
import { FC, ChangeEventHandler, useCallback, memo } from 'react'
import TextField from '@material-ui/core/TextField'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { IFileUploadInput } from './file-upload.types'
import * as styles from './file-upload.module.scss'

export const XtFileUpload: FC<IFileUploadInput> = memo(({ file, onChange, error, onBlur = () => {}, disabled = false }) => {
  const onUploadClick: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target!) {
        return
      }
      const fileInput = event.target! as HTMLInputElement
      const newFile = fileInput?.files && fileInput?.files.length > 0 ? fileInput.files[0] : null
      onChange(newFile)
    },
    [onChange]
  )

  const onUploadButtonClick: VoidFunction = () => {
    document.getElementById('hidden-document-file-input')?.click()
  }

  return (
    <div className={styles.fileUpload}>
      <TextField label="Filename" value={file?.name ?? ''} error={!!error} helperText={error} variant="outlined" disabled onBlur={onBlur} />
      <XtButton className={styles.browseButton} disabled={disabled} onClick={onUploadButtonClick} label="Browse" />
      <input disabled={disabled} hidden id="hidden-document-file-input" type="file" onChange={onUploadClick} onBlur={onBlur} />
    </div>
  )
})
