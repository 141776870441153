import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import {
  CommentCreateRequest,
  CommentUpdateRequest,
  IComment,
  ICommentsFilters,
  ICommentType,
  ICommentTypePayload,
  ICommentTypesFilters,
} from './comments.types'
import { DocumentType } from '../documents/documents.types'
import { prepareRequestParams } from '../common/utils/request.utils'
import { ISortOption } from '../components/table/table-head/table-head.types'
import { IHttpClient } from '../core/services/http-client'

export interface ICommentsService {
  getAll(pagination: IPaginationParams, filters?: ICommentsFilters): Promise<IPaginationData<IComment>>
  get(id: number): Promise<IComment>
  getForSource(source: DocumentType, sourceNumber: string, pagination: IPaginationParams): Promise<IPaginationData<IComment>>
  create(data: CommentCreateRequest): Promise<void>
  createAll(data: CommentCreateRequest[]): Promise<void>
  update(data: CommentUpdateRequest): Promise<void>
  updateAll(data: CommentCreateRequest[]): Promise<void>
  getCommentTypes(
    paginationParams?: IPaginationParams,
    filters?: ICommentTypesFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICommentType>>
  getCommentTypesForSource(source: DocumentType): Promise<ICommentType[]>
  deleteCommentType(name: string): Promise<void>
  updateCommentType(data: ICommentTypePayload): Promise<void>
  createCommentType(data: ICommentTypePayload): Promise<void>
  renameCommentType(id: string, name: string): Promise<void>
  getCommentTypeByModuleAndName(module: string, name: string, showDetail?: boolean): Promise<IResponse<ICommentType>>
}

export class CommentsService implements ICommentsService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(paginationParams: IPaginationParams, filters?: ICommentsFilters): Promise<IPaginationData<IComment>> {
    const params = prepareRequestParams(paginationParams, filters)

    const response = await this.apiClient.get<IPaginationResponse<IComment>>('/comment', { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(id: number): Promise<IComment> {
    const response = await this.apiClient.get<IPaginationResponse<IComment>>('/comment', { params: { id } })

    const {
      data: { data },
    } = response

    if (!Array.isArray(data) || !data.length) {
      throw new Error(`The comment with ID: ${id} cannot be found.`)
    }

    return data[0]
  }

  public async getForSource(
    source: DocumentType,
    sourceNumber: string,
    paginationParams: IPaginationParams
  ): Promise<IPaginationData<IComment>> {
    const params = prepareRequestParams(paginationParams)
    const response = await this.apiClient.get<IPaginationResponse<IComment>>(`/comment/${source}/${sourceNumber}`, { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async create(data: CommentCreateRequest): Promise<void> {
    await this.createAll([data])
  }

  public async createAll(comments: CommentCreateRequest[]): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/comment/create', { data: { comments } })
  }

  public async update(data: CommentUpdateRequest): Promise<void> {
    await this.updateAll([data])
  }

  public async updateAll(comments: CommentUpdateRequest[]): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/comment/update', { data: { comments } })
  }

  public async getCommentTypes(
    paginationParams?: IPaginationParams,
    filters?: ICommentTypesFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICommentType>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICommentType>>('/commenttype', { params })

    return { data: Array.isArray(data) ? data : [], total: status.totalrowcount }
  }

  public async getCommentTypesForSource(source: DocumentType): Promise<ICommentType[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<ICommentType>>(`/commenttype/${source}`)

    return Array.isArray(data) ? data : []
  }

  public async deleteCommentType(name: string): Promise<void> {
    const body = { data: { name } }
    await this.apiClient.post('/commenttype/delete', body)
  }

  public async updateCommentType(data: ICommentTypePayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/commenttype/update', body)
  }

  public async createCommentType(data: ICommentTypePayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/commenttype/create', body)
  }

  public async renameCommentType(id: string, name: string): Promise<void> {
    const body = { data: { name } }
    await this.apiClient.post(`/commenttype/${id}/rename`, body)
  }

  public async getCommentTypeByModuleAndName(module: string, name: string, showDetail?: boolean): Promise<IResponse<ICommentType>> {
    const params = { name, module, showDetail }
    const {
      data: { data, status },
    } = await this.apiClient.get<IResponse<ICommentType>>('/commenttype', { params })
    if (!data) {
      throw new Error(`No Comment Type with module ${module} and name ${name} exists.`)
    }

    return { data, status }
  }
}
