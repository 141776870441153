import * as React from 'react'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { TextareaAutosize } from '@material-ui/core'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { cls } from 'common/utils/utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtSelect } from 'components/controls/xt-select/xt-select'
import { useCoreModule } from 'core/core-module-hook'
import * as styles from './comment.module.scss'
import { parseServerDateWithDefault } from '../../common/utils/date.utils'
import { IXtCommentParams, IXtCommentState } from './comment.types'

const commentDateFormat = 'MMMM dd hh:mm a'

function defineOptions(options: IXtAutocompleteOption[], selectedCommentType: string): IXtAutocompleteOption[] {
  // We should add a comment type if it's not included in options. Otherwise the comment type dropdown will be displayed as empty.
  const selectedOptionsExist = selectedCommentType ? options.some(({ id }) => id === selectedCommentType) : null
  if (selectedOptionsExist === false) {
    options.push({ id: selectedCommentType, label: selectedCommentType })
  }
  return options
}

const defaultState: IXtCommentState = {
  type: '',
  value: '',
  loading: false,
  editMode: false,
}

export const XtComment: FC<IXtCommentParams> = React.memo(
  ({ disabled = false, comment, onUpdate, options, isNewComment = false, onNewCommentCancel }) => {
    const { ErrorHandler } = useCoreModule()

    const [state, setState] = useState<IXtCommentState>(defaultState)

    const commentTypeOptions = useMemo<IXtAutocompleteOption[]>(() => defineOptions([...options], state.type), [options, state.type])

    const resetToInitialState = (mode?: boolean): void => {
      setState((prevState) => ({
        ...prevState,
        value: comment?.comment ?? prevState.value,
        type: comment?.comment_type ?? '',
        editMode: mode ?? isNewComment,
      }))
    }

    useEffect(resetToInitialState, [])

    const cancel: VoidFunction = () => {
      resetToInitialState(false)
      if (isNewComment && onNewCommentCancel) {
        onNewCommentCancel()
      }
    }

    const save: VoidFunction = () => {
      if (state.loading || !state.type) {
        return
      }
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        onUpdate({ ...comment, comment: state.value, comment_type: state.type })
        setState((prevState) => ({ ...prevState, loading: false, editMode: false }))
      } catch (e) {
        setState((prevState) => ({ ...prevState, loading: false }))
        ErrorHandler.handleError(e)
      }
    }

    const onCommentChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = (e) => {
      const value = e?.target?.value
      setState((prevState) => ({ ...prevState, value }))
    }

    const commentDate = format(parseServerDateWithDefault(comment.created), commentDateFormat)

    const shouldShowPlaceholder = !state.editMode && !state.value

    const commentTextValue = !state.editMode && !state.value ? 'Comment' : state.value

    const onCommentTypeChange = (type: string) => setState((prevState) => ({ ...prevState, type }))

    return (
      <div className={cls(styles.xtComment)}>
        <div className={styles.xtCommentHeader}>
          <p className={styles.xtCommentUserName}>{comment?.username}</p>
          <span className={styles.xtCommentDate}>{commentDate}</span>
        </div>
        <div className={styles.xtCommentContent}>
          {!state.editMode && (
            <div className={cls(styles.xtCommentText, shouldShowPlaceholder ? styles.xtCommentTextPlaceholder : '')}>
              {commentTextValue}
            </div>
          )}
          {state.editMode && (
            <TextareaAutosize
              disabled={disabled}
              className={styles.xtCommentTextarea}
              placeholder="Comment"
              value={state.value}
              onChange={onCommentChange}
            />
          )}
          <div className={styles.xtCommentControls}>
            <XtSelect
              options={commentTypeOptions}
              label="Comment Type"
              onChange={onCommentTypeChange}
              disabled={disabled || !state.editMode || !isNewComment}
              value={state.type}
            />
            <XtButton
              hidden={state.editMode || disabled}
              label="Edit"
              onClick={() => setState((prevState) => ({ ...prevState, editMode: true }))}
            />
            <div hidden={!state.editMode} className={styles.xtCommentButtonsSection}>
              <XtButton disabled={state.loading} label="Cancel" onClick={cancel} />
              <XtButton disabled={state.loading || !state.value || !state.type} label="Done" loading={state.loading} onClick={save} />
            </div>
          </div>
        </div>
      </div>
    )
  }
)
