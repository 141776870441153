import { IUsersModuleServices } from './users.module'
import { Injector } from '../core/injector/injector.service'
import { IUsersService } from './users.service'
import { usersServiceToken } from './users.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveUsersModule(): IUsersModuleServices {
  const UsersService = Injector.resolve<IUsersService>(usersServiceToken)

  return { UsersService }
}

export const useUsersModule = moduleHookResolver<IUsersModuleServices>(resolveUsersModule)
