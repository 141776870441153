import * as React from 'react'
import { FC, useState, useCallback } from 'react'
import { saveAs } from 'file-saver'
import { IconButton, Modal, useMediaQuery } from '@material-ui/core'
import { confirmationMessages, xsMq } from 'common/constants'
import { AttachedDocumentTargetType, IAttachedDocumentWithFile, IAttachedDocument } from 'documents/documents.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtList } from 'components/list/list'
import { LoadingSpinner } from 'components/loading-spinner'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useCoreModule } from 'core/core-module-hook'
import { AttachSplitButton } from './attach-split-button/attach-split-button'
import * as styles from './documents.module.scss'
import { useDocumentsModule } from '../documents-module-hook'
import { convertDocumentFile, getDocumentActions, makeNewDocumentState } from './documents.utils'
import { XtDocumentDialog } from './document-dialog/document-dialog'
import {
  attachedDocumentActions,
  attachedDocumentColumns,
  attachedDocumentViewModeActions,
  initialDocumentState,
  initialPreviewImgState,
} from './documents.constants'
import { AttachedDocumentAction, IAttachedDocumentsDescription, IAttachedDocumentsState } from './documents.types'

export const XtDocuments: FC<IAttachedDocumentsDescription> = React.memo(
  ({ tableState, pagination, onDocumentCreate, onDocumentDelete, onDocumentUpdate, isViewMode, disableFileUpload, onDocumentsCreate }) => {
    const { DocumentsService } = useDocumentsModule()
    const { ErrorHandler, ToastService } = useCoreModule()

    const {
      itemId: itemIdToDelete,
      open: confirmationDialogOpen,
      openDialog: openDeleteConfirmationDialog,
      closeDialog: closeDeleteConfirmationDialog,
    } = useConfirmationDialog<IAttachedDocument>()

    const [state, setState] = useState<IAttachedDocumentsState>(initialDocumentState)
    const isMobile = useMediaQuery(xsMq)
    const onCloseDialog = useCallback(() => setState((prev) => ({ ...prev, isDialogOpen: false })), [setState])

    const attachNewDocument = useCallback((): void => {
      setState(makeNewDocumentState())
    }, [setState])

    const attachNewDocuments = useCallback(
      async (newFiles: FileList | null): Promise<void> => {
        if (!newFiles) {
          return
        }
        const newDocuments: IAttachedDocumentWithFile[] = Array.from(newFiles).map(convertDocumentFile)
        try {
          setState((prev) => ({ ...prev, isLoading: true }))
          await onDocumentsCreate(newDocuments)
          ToastService.showSuccess(`Documents have been created.`)
          setState((prev) => ({ ...prev, isLoading: false }))
        } catch (error) {
          ErrorHandler.handleError(error)
          setState((prev) => ({ ...prev, isLoading: false }))
        }
      },
      [ErrorHandler, ToastService, onDocumentsCreate]
    )

    const editDocument = (editedDocument: IAttachedDocument): void => {
      setState((prevState) => ({
        ...prevState,
        editedDocument,
        isDialogOpen: true,
        disableFileUpload: !disableFileUpload,
      }))
    }

    const previewDocument = async (fileLink: string, file?: File): Promise<void> => {
      setState((prev) => ({ ...prev, previewImg: { url: null, loading: true, open: true } }))
      const documentBlob = file ?? (await DocumentsService.loadFileData(fileLink))
      const url = URL.createObjectURL(documentBlob)
      setState((prev) => ({ ...prev, previewImg: { ...prev.previewImg, url, loading: false } }))
    }

    const downloadDocument = async (filename: string, file_link: string, file?: File): Promise<void> => {
      const document = file ?? (await DocumentsService.loadFileData(file_link))
      saveAs(document, filename)
    }

    const onPreviewClose = useCallback(() => {
      if (state.previewImg.url) {
        URL.revokeObjectURL(state.previewImg.url)
      }
      setState((prev) => ({ ...prev, previewImg: initialPreviewImgState }))
    }, [state])

    const onAction = useCallback(
      (item: IAttachedDocument, action: AttachedDocumentAction): void => {
        switch (action) {
          case AttachedDocumentAction.Delete:
            void openDeleteConfirmationDialog(item)
            break
          case AttachedDocumentAction.Edit:
            editDocument(item)
            break
          case AttachedDocumentAction.Preview:
            void previewDocument(item.file_link, item?.file)
            break
          case AttachedDocumentAction.Download:
            void downloadDocument(item.name, item.file_link, item?.file)
            break
          default:
            throw new Error('Incorrect action type')
        }
      },
      [downloadDocument, editDocument, openDeleteConfirmationDialog, previewDocument]
    )

    const handleDeletion = useCallback<VoidFunction>(async () => {
      closeDeleteConfirmationDialog()
      if (itemIdToDelete) {
        try {
          setState({ ...state, isLoading: true })
          await onDocumentDelete(itemIdToDelete)
          ToastService.showSuccess(`Document ${itemIdToDelete.name} has been deleted.`)
          setState({ ...state, isLoading: false })
        } catch (error) {
          ErrorHandler.handleError(error)
          setState({ ...state, isLoading: false })
        }
      }
    }, [closeDeleteConfirmationDialog, itemIdToDelete, onDocumentDelete, state])

    const handleRowClick = useCallback<(document: IAttachedDocument) => void>((document) => {
      if (document.target_type !== AttachedDocumentTargetType.File) {
        return
      }
      editDocument(document)
    }, [])

    return (
      <div className={styles.documentsContent}>
        <XtConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationMessages.deleted}
          title="Delete Document"
          confirmationButtonLabel="Delete"
          onConfirm={handleDeletion}
          onClose={closeDeleteConfirmationDialog}
        />

        <Modal open={state.previewImg.open} className={styles.documentPreviewDialogRoot}>
          <div className={styles.documentPreviewDialogContainer}>
            <IconButton className={styles.closeIcon} onClick={onPreviewClose}>
              <SvgIcon className={styles.closeIconSize} iconId={SvgIconIds.CLOSE_ICON} />
            </IconButton>
            {state.previewImg.loading ? (
              <LoadingSpinner className={styles.documentPreviewLoadingSpinner} />
            ) : (
              state.previewImg.url && <img className={styles.documentPreviewImg} src={state.previewImg.url} alt="Preview" />
            )}
          </div>
        </Modal>
        <XtDialog className="xt-modal-details-content" open={state.isDialogOpen} animation={XtDialogAnimation.SlideAnimation}>
          <XtDocumentDialog
            onClose={onCloseDialog}
            onCreate={onDocumentCreate}
            onUpdate={onDocumentUpdate}
            editedDocument={state.editedDocument}
            disabled={isViewMode}
            disableFileUpload={state.disableFileUpload}
          />
        </XtDialog>
        <AttachSplitButton
          isMobile={isMobile}
          attachMultipleFiles={attachNewDocuments}
          disabled={isViewMode}
          attachFIle={attachNewDocument}
        />

        <div className={styles.documentsList}>
          <XtList
            data={tableState.data}
            columns={attachedDocumentColumns}
            actions={isViewMode ? attachedDocumentViewModeActions : attachedDocumentActions}
            onAction={onAction}
            pagination={pagination}
            getItemActions={getDocumentActions}
            isMobile={isMobile}
            loading={tableState.loading || state.isLoading}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    )
  }
)
