import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { XtTable } from '../table/table'
import { XtCardList } from '../card-list/card-list'
import { IActionTableParams, TableActionHandler } from '../../common/types/table.types'
import { IObjectWithId } from '../../common/common.types'
import { ICardData } from '../card-list/card-list.types'
import { IXtTableColumn, TableCell } from '../table/table-head/table-head.types'
import { calculateActionsWidth, IXtTableCellAction, XtTableCellActions } from '../table/table-cell-actions/table-cell-actions'
import { getFieldValue } from '../table/table.utils'
import { ITableRow } from '../table/table.types'
import './list.scss'

function convertColumnsToMobileData<TData extends ITableRow>(items: TData[], columns: IXtTableColumn<TData>[]): ICardData<TData>[] {
  return items.map((item) => ({
    data: item,
    fields: columns.map(({ field, headerName, converter, renderCell }) => {
      const value = field ? getFieldValue<TData>(item, field!, converter) : undefined
      return { value, label: headerName ?? '', cellTemplate: renderCell?.(item) }
    }),
  }))
}

function shouldDisplayActions<TData, Action>(onAction?: TableActionHandler<TData, Action>, actions?: IXtTableCellAction[]): boolean {
  return typeof onAction === 'function' && !!actions?.length
}

function createActionColumn<TData extends IObjectWithId, Action>(
  onAction?: TableActionHandler<TData, Action>,
  actions: IXtTableCellAction[] = [],
  getItemAction?: (item: TData, actions: IXtTableCellAction[]) => IXtTableCellAction[]
): IXtTableColumn<TData> | null {
  if (!shouldDisplayActions(onAction, actions)) {
    return null
  }

  return {
    id: 'actions',
    renderCell: (item) => (
      <XtTableCellActions
        className="xt-table-cell-actions"
        actions={getItemAction ? getItemAction(item, actions) : actions}
        onClick={(action) => onAction!(item, (action as unknown) as Action)} // TODO fix type casting
      />
    ),
    flex: `0 0 ${calculateActionsWidth(actions.length)}px`,
    width: calculateActionsWidth(actions.length),
    headerName: TableCell.Action,
    sticky: true,
    height: '100%',
    align: 'center',
    unsortable: true,
  }
}

export function XtList<TData extends ITableRow, Action>({
  data,
  loading = false,
  pagination,
  isMobile = false,
  onRowClick,
  columns,
  onAction,
  actions = [],
  convertToMobileData,
  className,
  cardListClassName,
  getItemActions,
  sortOptions,
  onColumnHeaderClick,
}: IActionTableParams<TData, Action>): React.ReactElement {
  const actionColumns = useMemo<IXtTableColumn<TData>[]>(() => {
    const actionColumn = createActionColumn(onAction, actions, getItemActions)
    return actionColumn ? [...columns, actionColumn] : [...columns]
  }, [columns, onAction, actions])
  const loadMoreData = useCallback<() => Promise<void>>(async () => pagination?.loadMore(pagination.page + 1), [pagination])

  const mobileData = useMemo(
    () => (typeof convertToMobileData === 'function' ? convertToMobileData(data) : convertColumnsToMobileData(data, actionColumns)),
    [data, convertToMobileData, actionColumns]
  )

  return isMobile ? (
    <XtCardList
      canLoadMore={pagination?.canLoadMore ?? false}
      loading={loading}
      loadMore={loadMoreData}
      onCardClick={onRowClick}
      data={mobileData}
      className={cardListClassName}
    />
  ) : (
    <XtTable
      loading={loading}
      onRowClick={onRowClick}
      rows={data}
      columns={actionColumns}
      pagination={pagination}
      className={className}
      rowClassName="xt-grid-table-button-cell"
      sortOptions={sortOptions}
      onColumnHeaderClick={onColumnHeaderClick}
    />
  )
}
