import { ITableState } from 'common/hooks/useTable'
import { ITablePagination } from 'common/types/table.types'
import { IAttachedDocument, IAttachedDocumentWithFile } from 'documents/documents.types'

export type AttachedDocumentCallback = (attachedDocument: IAttachedDocument) => Promise<void>

export interface IAttachedDocumentsDescription {
  onDocumentCreate: (attachedDocument: IAttachedDocumentWithFile) => Promise<boolean>
  onDocumentsCreate: (document: IAttachedDocumentWithFile[]) => Promise<boolean>
  onDocumentUpdate: (attachedDocument: IAttachedDocument) => Promise<boolean>
  onDocumentDelete: AttachedDocumentCallback
  isViewMode: boolean
  tableState: ITableState<IAttachedDocument, {}>
  pagination: ITablePagination
  disableFileUpload: boolean
}

export interface IAttachedDocumentsState {
  editedDocument: IAttachedDocument | null
  isDialogOpen: boolean
  isLoading: boolean
  newFile?: File | null
  previewImg: { url: string | null; loading: boolean; open: boolean }
  disableFileUpload: boolean
}

export enum AttachedDocumentAction {
  Delete = 'Delete',
  Download = 'Download',
  Preview = 'Preview',
  Edit = 'Edit',
}

export type AttachedDocumentActionCallback = (item: IAttachedDocument, action: AttachedDocumentAction) => void
