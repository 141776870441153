import { dateToServerFormat } from 'common/utils/date.utils'
import {
  CharacteristicDialogFormField,
  CharacteristicValue,
  IAppliedCharacteristicNew,
  ICharacteristicFormState,
} from '../characteristics.types'
import { ICharacteristicFormValues } from './characteristic-dialog.types'

function convertCharacteristicValue(value: CharacteristicValue): CharacteristicValue {
  if (value instanceof Date) {
    return dateToServerFormat(value)
  }
  return value
}

export const convertCharacteristicFormValues = (data: ICharacteristicFormState): IAppliedCharacteristicNew => ({
  characteristic: data?.characteristic?.name ?? '',
  characteristic_value: convertCharacteristicValue(data.characteristic_value),
  characteristic_group: data?.characteristic?.characteristic_group ?? '',
  default_characteristic: false,
})

export const defaultCharacteristicFormValues = {
  [CharacteristicDialogFormField.Characteristic]: null,
  [CharacteristicDialogFormField.Value]: '',
}

export const characteristicFormValues = (data: ICharacteristicFormState | null): ICharacteristicFormValues =>
  data
    ? {
        ...data,
        characteristic: data.characteristic
          ? {
              ...data.characteristic,
              id: data?.characteristic?.name ?? '',
              label: data?.characteristic?.name ?? '',
            }
          : null,
      }
    : defaultCharacteristicFormValues
