import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { IRequestedSite, ISiteFilters } from 'products/items/items.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'

export interface ISitesService {
  getAll(pagination?: IPaginationParams, filters?: ISiteFilters): Promise<IPaginationData<IRequestedSite>>
  get(site: string): Promise<IRequestedSite>
}

export class SitesService implements ISitesService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams, filters?: ISiteFilters): Promise<IPaginationData<IRequestedSite>> {
    const params = prepareRequestParams(pagination, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRequestedSite>>(`/site`, {
      params,
    })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(site: string): Promise<IRequestedSite> {
    const { data } = await this.apiClient.get<IResponse<IRequestedSite>>(`/site/${site}`)

    return data.data
  }
}
