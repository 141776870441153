import { IAsyncRoute, IRoute } from '../common/routing/routing.types'
import { crmRoutePath, crmRoutes } from './crm.constants'
import { ProspectsGuard } from './crm-prospects/prospects.guard'
import { CRMGuard } from './crm.guard'
import { CrmAccountsGuard } from './crm-accounts/crm-accounts.guard'
import { CustomersGuard } from './customers/customers.guard'
import { OpportunitiesGuard } from './opportunities/opportunities.guard'
import { CustomerDetailsGuard } from './customers/customer-details/customer-details.guard'
import { buildPageComponent } from '../common/routing/routing.utils'
import { XtMode } from '../common/common.types'
import { ProspectDetailsGuard } from './crm-prospects/crm-prospect-details/prospect-details.guard'
import { OpportunityDetailsGuard } from './opportunities/opportunity-details/opportunity-details.guard'
import { CrmAccountDetailsGuard } from './crm-accounts/crm-account-details/crm-account-details.guard'
import { ContactsGuard } from './contacts/contacts.guard'
import { ContactsDetailsGuard } from './contacts/contacts-details.guard'
import { CrmModule, CRMModuleDefinition } from './crm.module'

function crmRouteFactory({
  CRMAccountsList,
  CrmContactsList,
  CRMProspectsList,
  CrmProspectDetailsPage,
  CustomersList,
  CRMAccountDetailsPage,
  CustomerDetailsPage,
  OpportunitiesList,
  OpportunityDetailsPage,
  ContactsDetailsPage,
}: CRMModuleDefinition): IRoute[] {
  return [
    {
      path: crmRoutes.accounts,
      name: 'Accounts',
      guards: [CrmAccountsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CRMAccountsList,
        },
        {
          path: `/:id/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, CRMAccountDetailsPage),
          guards: [CrmAccountDetailsGuard],
        },
        {
          path: `/:id/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, CRMAccountDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, CRMAccountDetailsPage),
          guards: [CrmAccountDetailsGuard],
        },
      ],
    },
    {
      path: crmRoutes.customers,
      name: 'Customers',
      guards: [CustomersGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CustomersList,
        },
        {
          path: `/:customerNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, CustomerDetailsPage),
          guards: [CustomerDetailsGuard],
        },
        {
          path: `/:customerNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, CustomerDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, CustomerDetailsPage),
          guards: [CustomerDetailsGuard],
        },
      ],
    },
    {
      path: crmRoutes.prospects,
      name: 'Prospects',
      guards: [ProspectsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CRMProspectsList,
        },
        {
          path: `/:prospectNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, CrmProspectDetailsPage),
          guards: [ProspectDetailsGuard],
        },
        {
          path: `/:prospectNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, CrmProspectDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, CrmProspectDetailsPage),
          guards: [ProspectDetailsGuard],
        },
      ],
    },
    {
      path: crmRoutes.opportunities,
      name: 'Opportunities',
      guards: [OpportunitiesGuard],
      children: [
        {
          path: '',
          exact: true,
          component: OpportunitiesList,
        },
        {
          path: `/:opportunityNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, OpportunityDetailsPage),
          guards: [OpportunityDetailsGuard],
        },
        {
          path: `/:opportunityNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, OpportunityDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, OpportunityDetailsPage),
          guards: [OpportunityDetailsGuard],
        },
      ],
    },
    {
      path: crmRoutes.contacts,
      name: 'Contacts',
      guards: [ContactsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CrmContactsList,
        },
        {
          path: `/:contactNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, ContactsDetailsPage),
          guards: [ContactsDetailsGuard],
        },
        {
          path: `/:contactNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, ContactsDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, ContactsDetailsPage),
          guards: [ContactsDetailsGuard],
        },
      ],
    },
  ]
}

export const crmRoute: IAsyncRoute<CRMModuleDefinition> = {
  path: crmRoutePath,
  module: CrmModule,
  name: 'CRM',
  guards: [CRMGuard],
  childrenRoutesFactory: crmRouteFactory,
}
