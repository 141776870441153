export const crmRoutePath: string = '/crm'

export const crmRoutes = {
  accounts: 'accounts',
  customers: 'customers',
  prospects: 'prospects',
  opportunities: 'opportunities',
  contacts: 'contacts',
}

export const crmModule = 'CRMModule'
export const opportunitiesServiceToken = 'OpportunitiesService'
export const opportunitiesUtilsServiceToken = 'OpportunitiesUtilsService'
export const crmAccountsServiceToken = 'CRMAccountsService'
export const crmAccountsUtilsServiceToken = 'CrmAccountsUtilsServiceToken'
export const prospectsServiceToken = 'ProspectsService'
export const prospectsUtilsServiceToken = 'ProspectsUtilsService'
export const customersServiceToken = 'CustomersService'
export const customersUtilsServiceToken = 'CustomersUtilsService'
export const crmContactsUtilsServiceToken = 'CRMContactsListUtilsService'
