import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../../common/common.types'
import { prepareRequestParams } from '../../common/utils/request.utils'
import { IHttpClient } from './http-client'
import { IXtAutocompleteOption } from '../../components/controls/xt-autocomplete/xt-autocomplete.types'

export interface ICountry {
  country_code: string
  country: string
  currency: string
  currency_symbol: string
}

export interface IState {
  state_code: string
  state: string
  country: string
}

export interface ICurrencyRate {
  effective_date: string
  expiry_date: string
  rate: number
}

export interface ICurrency {
  currency: string
  symbol: string
  iso_code: string
  base_currency: boolean
  conversion_basis: string
  rates: ICurrencyRate[]
}

export interface ICurrenciesFilters {
  currency?: string | null
}
export interface ICountryFilters {
  country_pattern?: string | null
}
export interface IStateFilters {
  state_pattern?: string | null
}

export type CountryOption = IXtAutocompleteOption & { hasStates?: boolean }
export type CountryStates = { initialState: IXtAutocompleteOption | string | null; initialCountry: CountryOption | null }

export type CurrencyOption = ICurrency & IXtAutocompleteOption

export interface IDictionaryService {
  getAll(params: IPaginationParams, filters?: ICountryFilters): Promise<IPaginationData<ICountry>>
  get(countryCode: string): Promise<ICountry>
  getStates(countryCode: string, params?: IPaginationParams, filters?: IStateFilters): Promise<IPaginationData<IState>>
  getCurrencies(params: IPaginationParams, filters?: ICurrenciesFilters): Promise<IPaginationData<ICurrency>>
}

export class DictionaryService implements IDictionaryService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(pagination: IPaginationParams, filters?: ICountryFilters): Promise<IPaginationData<ICountry>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICountry>>('/countries', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(countryCode: string): Promise<ICountry> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ICountry>>(`/countries/${countryCode}`)
    if (!Object.keys(data).length) {
      throw new Error(`Country with the code: ${countryCode} is not found.`)
    }
    return data
  }

  public async getStates(countryCode: string, pagination?: IPaginationParams, filters?: IStateFilters): Promise<IPaginationData<IState>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IState>>(`/states/${countryCode}`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCurrencies(pagination: IPaginationParams, filters?: ICurrenciesFilters): Promise<IPaginationData<ICurrency>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICurrency>>('/currency', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
