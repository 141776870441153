import { IAsyncRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { vendorRoutePath } from './vendor.constants'
import { VendorModule, VendorModuleDefinition } from './vendor.module'

export const vendorRoute: IAsyncRoute<VendorModuleDefinition> = {
  path: vendorRoutePath,
  name: 'Vendors',
  // guards: [VendorGuard],
  module: VendorModule,
  childrenRoutesFactory: ({ VendorList, VendorDetails }) => [
    {
      path: '',
      exact: true,
      component: VendorList,
    },
    {
      path: `/:vendorNumber/${XtMode.Edit}`,
      component: (props) => buildPageComponent(props, XtMode.Edit, VendorDetails),
      // guards: [VendorDetailsGuard],
    },
    {
      path: `/:vendorNumber/${XtMode.View}`,
      component: (props) => buildPageComponent(props, XtMode.View, VendorDetails),
    },
    {
      path: `/${XtMode.New}`,
      component: (props) => buildPageComponent(props, XtMode.New, VendorDetails),
      //   guards: [VendorDetailsGuard],
    },
  ],
}
