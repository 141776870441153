import * as yup from 'yup'
import { StringSchema } from 'yup'
import { ProfileDetailsFormField } from './profile.types'
import { validationMessage } from '../common/validation/validation'
import { PasswordChangeFormField } from './password-dialog/password-dialog.types'

export const profileValidation = yup.object().shape(
  {
    [ProfileDetailsFormField.Locale]: yup.object().required(validationMessage.isRequired).nullable(),
    [PasswordChangeFormField.Password]: yup
      .string()
      .when(PasswordChangeFormField.Verify, (verify: string, schema: StringSchema) =>
        verify ? schema.required(validationMessage.isRequired) : schema
      ),
    [PasswordChangeFormField.Verify]: yup
      .string()
      .when(PasswordChangeFormField.Password, (password: string, schema: StringSchema) =>
        password
          ? schema
              .required(validationMessage.isRequired)
              .oneOf([yup.ref(PasswordChangeFormField.Password)], validationMessage.passwordMatch)
          : schema
      ),
  },
  [[PasswordChangeFormField.Password, PasswordChangeFormField.Verify]]
)
