import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { commentsServiceToken } from 'comments/comments.constants'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { IDocumentsService } from 'documents/documents.types'
import { ICommentsService } from 'comments/comments.service'
import { DocumentsModule } from 'documents/documents.module'
import { CommentsModule } from 'comments/comments.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { TasksModule } from 'tasks/tasks.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { ICustomersService } from './customers/customers.types'
import { ICRMAccountService } from './crm-accounts/crm-accounts.service'
import { IProspectsService } from './crm-prospects/crm-prospects.service'
import {
  crmAccountsServiceToken,
  crmAccountsUtilsServiceToken,
  crmContactsUtilsServiceToken,
  crmModule,
  customersServiceToken,
  customersUtilsServiceToken,
  opportunitiesServiceToken,
  opportunitiesUtilsServiceToken,
  prospectsServiceToken,
  prospectsUtilsServiceToken,
} from './crm.constants'
import { IContactService } from '../contacts/contacts.service'
import { IOpportunitiesService } from './opportunities/opportunities.service'
import { ICRMAccountsUtilsService } from './crm-accounts/crm-accounts-utils.service'
import { IOpportunitiesUtilsService } from './opportunities/opportunities-utils.service'
import { ICustomersUtilsService } from './customers/customers-utils.service'
import { IProspectsUtilsService } from './crm-prospects/prospects-utils.service'
import { ICRMContactsUtilsService } from './contacts/contacts-utils.service'
import { contactsServiceToken } from '../contacts/contacts.constants'
import { ContactsModule } from '../contacts/contacts.module'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'
import { httpClientToken, IHttpClient } from '../core/services/http-client'
import { IErrorHandler } from '../core/services/error-handler.service'
import { errorHandlerServiceToken } from '../core/core.constants'

export type CRMModuleDefinition = typeof import('./crm.exports')

export interface ICRMModuleServices {
  ProspectsService: IProspectsService
  ProspectsUtilsService: IProspectsUtilsService
  CustomersService: ICustomersService
  CustomersUtilsService: ICustomersUtilsService
  OpportunitiesService: IOpportunitiesService
  OpportunitiesUtilsService: IOpportunitiesUtilsService
  CRMAccountService: ICRMAccountService
  CRMAccountsUtilsService: ICRMAccountsUtilsService
  ContactsUtilsService: ICRMContactsUtilsService
}

export const CrmModule: ILazyModule<CRMModuleDefinition> = {
  name: crmModule,
  resolver: () => import('./crm.exports'),
  initializer: ({
    ProspectsService,
    CustomersService,
    CRMAccountService,
    OpportunitiesService,
    CRMAccountsUtilsService,
    OpportunitiesUtilsService,
    CustomersUtilsService,
    ProspectsUtilsService,
    ContactsUtilsService,
  }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    const documentsService = Injector.resolve<IDocumentsService>(documentsServiceToken)
    const commentsService = Injector.resolve<ICommentsService>(commentsServiceToken)

    Injector.register(prospectsServiceToken, () => new ProspectsService(httpClient, documentsService), Scope.Singleton)
    Injector.register(
      customersServiceToken,
      (injector) => {
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)
        return new CustomersService(httpClient, documentsService, prospectsService)
      },
      Scope.Singleton
    )
    Injector.register(
      crmAccountsServiceToken,
      (injector) => {
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)

        return new CRMAccountService(httpClient, commentsService, documentsService, customersService, prospectsService)
      },
      Scope.Singleton
    )
    Injector.register(
      opportunitiesServiceToken,
      (injector) => {
        const accountsService = injector.resolve<ICRMAccountService>(crmAccountsServiceToken)

        return new OpportunitiesService(httpClient, commentsService, documentsService, accountsService)
      },
      Scope.Singleton
    )

    Injector.register<ICRMAccountsUtilsService>(
      crmAccountsUtilsServiceToken,
      (injector) => {
        const accountsService = injector.resolve<ICRMAccountService>(crmAccountsServiceToken)

        return new CRMAccountsUtilsService(accountsService)
      },
      Scope.Singleton
    )

    Injector.register<IProspectsUtilsService>(
      prospectsUtilsServiceToken,
      (injector) => {
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)

        return new ProspectsUtilsService(prospectsService)
      },
      Scope.Singleton
    )

    Injector.register<IOpportunitiesUtilsService>(
      opportunitiesUtilsServiceToken,
      (injector) => {
        const opportunitiesService = injector.resolve<IOpportunitiesService>(opportunitiesServiceToken)

        return new OpportunitiesUtilsService(opportunitiesService)
      },
      Scope.Singleton
    )

    Injector.register<ICustomersUtilsService>(
      customersUtilsServiceToken,
      (injector) => {
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)

        return new CustomersUtilsService(customersService, errorHandler)
      },
      Scope.Singleton
    )

    Injector.register<ICRMContactsUtilsService>(
      crmContactsUtilsServiceToken,
      (injector) => {
        const contactsService = injector.resolve<IContactService>(contactsServiceToken)

        return new ContactsUtilsService(contactsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    AuthModule,
    DocumentsModule,
    CharacteristicsModule,
    CommentsModule,
    ShipmentsModule,
    TasksModule,
    ContactsModule,
  ],
}
