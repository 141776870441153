import React, { FC } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import * as styles from './xt-accordion.module.scss'
import { cls } from '../../common/utils/utils'

export enum AccordionTheme {
  Dark,
  Light,
}

export interface IXtAccordion {
  summary: string | JSX.Element
  expanded?: boolean
  defaultExpanded?: boolean
  theme?: AccordionTheme
  className?: string
  summaryContentClassName?: string
  invalid?: boolean
  onChange?(expanded: boolean): void
}

export const XtAccordion: FC<IXtAccordion> = ({
  children,
  summary,
  invalid,
  className,
  summaryContentClassName,
  expanded,
  defaultExpanded,
  theme = AccordionTheme.Dark,
  onChange,
}) => {
  const rootClass = theme === AccordionTheme.Dark ? styles.muiAccordionDark : styles.muiAccordion

  return (
    <Accordion
      elevation={0}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      className={cls(styles.xtAccordion, className, invalid && styles.xtAccordionInvalid)}
      classes={{
        root: rootClass,
        expanded: cls(rootClass, styles.muiAccordionMargin),
      }}
      onChange={(_, isExpanded) => onChange && onChange(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<SvgIcon iconId={SvgIconIds.EXPAND_MORE} className={styles.muiExpandIconSize} />}
        classes={{
          root: cls(styles.muiAccordionSummary),
          content: cls(styles.muiAccordionSummaryContent, summaryContentClassName),
          expanded: styles.muiAccordionSummaryExpanded,
          expandIcon: styles.muiExpandIcon,
        }}
      >
        <Typography className={styles.titleSummaryContent}>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.muiAccordionDetails }}>{children}</AccordionDetails>
    </Accordion>
  )
}
