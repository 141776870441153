import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { TasksModule } from 'tasks/tasks.module'
import { CrmModule } from 'crm/crm.module'
import {
  dashboardModule,
  opportunitiesKanbanServiceToken,
  opportunitiesKanbanUtilsServiceToken,
  tasksKanbanServiceToken,
  tasksKanbanUtilsServiceToken,
} from './dashboard.constants'
import { IOpportunitiesKanbanService } from './opportunities-kanban/opportunities-kanban.service'
import { ITasksKanbanService } from './tasks-kanban/tasks-kanban.service'
import { IOpportunitiesKanbanUtilsService } from './opportunities-kanban/opportunities-kanban-utils.service'
import { ITasksKanbanUtilsService } from './tasks-kanban/tasks-kanban-utils.service'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'
import { KanbanModule } from '../kanban/kanban.module'

export type DashboardModuleDefinition = typeof import('./dashboard.exports')

export interface IDashboardModuleService {
  OpportunitiesKanbanService: IOpportunitiesKanbanService
  OpportunitiesKanbanUtilsService: IOpportunitiesKanbanUtilsService
  TasksKanbanService: ITasksKanbanService
  TasksKanbanUtilsService: ITasksKanbanUtilsService
}

export const DashboardModule: ILazyModule<DashboardModuleDefinition> = {
  name: dashboardModule,
  resolver: () => import('./dashboard.exports'),
  initializer: ({ OpportunitiesKanbanService, TasksKanbanService, TasksKanbanUtilsService, OpportunitiesKanbanUtilsService }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    Injector.register(opportunitiesKanbanServiceToken, () => new OpportunitiesKanbanService(httpClient), Scope.Singleton)
    Injector.register(tasksKanbanServiceToken, () => new TasksKanbanService(httpClient), Scope.Singleton)
    Injector.register(
      opportunitiesKanbanUtilsServiceToken,
      (injector) => {
        const service = injector.resolve<IOpportunitiesKanbanService>(opportunitiesKanbanServiceToken)
        return new OpportunitiesKanbanUtilsService(service)
      },
      Scope.Singleton
    )
    Injector.register<ITasksKanbanUtilsService>(
      tasksKanbanUtilsServiceToken,
      (injector) => {
        const service = injector.resolve<ITasksKanbanService>(tasksKanbanServiceToken)
        return new TasksKanbanUtilsService(service)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, KanbanModule, AuthModule, TasksModule, CrmModule],
}
