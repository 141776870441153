import React from 'react'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import './xt-autocomplete.scss'

export function defineAutocompleteOption(id: string, label?: string): IXtAutocompleteOption
export function defineAutocompleteOption(id: string | null | undefined, label?: string): IXtAutocompleteOption | null

export function defineAutocompleteOption(id: string | null | undefined, label?: string): IXtAutocompleteOption | null {
  return id ? { id, label: label ?? id } : null
}

// TODO return null instead of empty string
export function parseAutocompleteOption(data: IXtAutocompleteOption | null): string {
  return data?.id ? data.id : ''
}

export function isAutocompleteValue(value: unknown): value is IXtAutocompleteOption {
  return typeof value === 'object' && value !== null && 'id' in value && 'label' in value
}

export function getAutocompleteInputLabelAsId(option: IXtAutocompleteOption | null): string {
  return option ? option.id : ''
}

export function getAutocompleteInputLabelInline(option: IXtAutocompleteOption | null): string {
  return option ? `${option.id}-${option.label}` : ''
}

export function renderAutocompleteOption(option: IXtAutocompleteOption): React.ReactElement {
  return (
    <div title={option.label} className="xt-autocomplete-container" key={option.id}>
      <div className="xt-text-truncated">{option.label}</div>
    </div>
  )
}

export function renderInlineOption(option: IXtAutocompleteOption): React.ReactElement {
  return (
    <div className="xt-autocomplete-container">
      <div className="xt-text-truncated">{getAutocompleteInputLabelInline(option)}</div>
    </div>
  )
}

export function renderColumnOption(option: IXtAutocompleteOption): React.ReactElement {
  const title = `${option.id?.toString()}\n${option.label}`
  return (
    <div className="xt-autocomplete-container" title={title}>
      <div className="xt-text-truncated">{option.id?.toString()}</div>
      <div className="xt-text-truncated">{option.label}</div>
    </div>
  )
}
