import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { IQuotesService } from 'sales-shared/quotes.service'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CrmModule } from 'crm/crm.module'
import { ProductsModule } from 'products/products.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { quotesServiceToken } from 'sales-shared/sales-shared.constants'
import { SalesSharedModule } from 'sales-shared/sales-shared.module'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { IToastService } from 'toast/toast.service'
import { toastServiceToken } from 'toast/toast.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { IQuotesUtilsService } from './quotes-utils.service'
import { quotesModule, quotesUtilsServiceToken } from './quotes.constants'

export type QuotesModuleDefinition = typeof import('./quotes.exports')

export interface IQuotesModuleServices {
  QuotesUtilsService: IQuotesUtilsService
}

export const QuotesModule: ILazyModule<QuotesModuleDefinition> = {
  name: quotesModule,
  resolver: () => import('./quotes.exports'),
  initializer: ({ QuotesUtilsService }) => {
    Injector.register<IQuotesUtilsService>(
      quotesUtilsServiceToken,
      (injector) => {
        const quotesService = injector.resolve<IQuotesService>(quotesServiceToken)
        const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        const toastService = Injector.resolve<IToastService>(toastServiceToken)

        return new QuotesUtilsService(quotesService, toastService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule, ProductsModule, SalesSharedModule, CrmModule, CharacteristicsModule, ShipmentsModule],
}
