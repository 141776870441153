import * as React from 'react'
import { useEffect } from 'react'
import { IXtAutocompleteOption, IXtAutocompleteParams } from './xt-autocomplete.types'
import { XtAutocompletePlain } from './xt-autocomplete-plain'
import { useXtAutocomplete } from './xt-autocomplete-hook'

export function XtAutocomplete<Option extends IXtAutocompleteOption, TFilters>({
  value,
  onChange,
  onBlur,
  loadOptions,
  limit,
  renderOption,
  placeholder,
  disabled,
  className,
  error,
  hidden,
  getInputLabel,
  noOptionsText,
  getOptionDisabled,
  filters,
}: IXtAutocompleteParams<Option, TFilters>): React.ReactElement {
  const { options, loading, reset, search, loadMoreOptions } = useXtAutocomplete(loadOptions, filters, false, limit)

  useEffect(() => {
    void reset(filters)
  }, [reset, filters])

  return (
    <XtAutocompletePlain
      hidden={hidden}
      error={error}
      placeholder={placeholder}
      getInputLabel={getInputLabel}
      value={value}
      onChange={onChange}
      options={options}
      onBlur={onBlur}
      loading={loading}
      getOptionDisabled={getOptionDisabled}
      className={className}
      renderOption={renderOption}
      noOptionsText={noOptionsText}
      disabled={disabled}
      loadMore={loadMoreOptions}
      reset={() => reset(filters)}
      filter={search}
    />
  )
}
