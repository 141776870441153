// extracted by mini-css-extract-plugin
export var root = "comment_root__I5A4Y";
export var xtContent = "comment_xt-content__sR5zQ";
export var xtContentWithRemarks = "comment_xt-content-with-remarks__eJ9JS";
export var muiFormHelperTextRoot = "comment_MuiFormHelperText-root__xUY9Y";
export var muiTableCellRoot = "comment_MuiTableCell-root__G2LWy";
export var xtListPageContainer = "comment_xt-list-page-container__Dadgk";
export var muiInputBaseRoot = "comment_MuiInputBase-root__nrtHS";
export var muiTextFieldRoot = "comment_MuiTextField-root__NySu5";
export var muiTablePaginationRoot = "comment_MuiTablePagination-root__4Asze";
export var muiTablePaginationSelectRoot = "comment_MuiTablePagination-selectRoot__nIrnt";
export var muiTablePaginationSelect = "comment_MuiTablePagination-select__G1B-o";
export var xtPageTitle = "comment_xt-page-title__tXNpd";
export var xtSectionBorder = "comment_xt-section-border__HESNT";
export var xtPageHeader = "comment_xt-page-header__D7cCn";
export var xtDialogScrollable = "comment_xt-dialog-scrollable__grQ75";
export var reactToastNotificationsContainer = "comment_react-toast-notifications__container__B-AAm";
export var xtScrollbarStyle = "comment_xt-scrollbar-style__EnMKZ";
export var xtModalDetailsContent = "comment_xt-modal-details-content__FKyA+";
export var xtDialogDetailsContent = "comment_xt-dialog-details-content__46iO1";
export var xtLazyModuleLoaderTabContainer = "comment_xt-lazy-module-loader-tab-container__vB7RS";
export var xtTextTruncated = "comment_xt-text-truncated__hkzX5";
export var xtSummaryContent = "comment_xt-summary-content__oYJGo";
export var xtSummaryLabel = "comment_xt-summary-label__AUg2n";
export var xtComment = "comment_xtComment__AQ4qN";
export var xtCommentHeader = "comment_xtCommentHeader__JfxKI";
export var xtCommentUserName = "comment_xtCommentUserName__ny3fP";
export var xtCommentDate = "comment_xtCommentDate__wM9iv";
export var xtCommentContent = "comment_xtCommentContent__LCqi3";
export var xtCommentTextarea = "comment_xtCommentTextarea__fAuw1";
export var xtCommentText = "comment_xtCommentText__NsxuL";
export var xtCommentTextPlaceholder = "comment_xtCommentTextPlaceholder__s8qeI";
export var xtCommentControls = "comment_xtCommentControls__-1fxb";
export var xtCommentButtonsSection = "comment_xtCommentButtonsSection__wvCjE";