import * as breakpoints from '../assets/sass/breakpoints.module.scss'

export interface IGlobalConstants {
  apiUrl: string
  dateFormat: string
  serverDateFormat: string
  paginationLimit: number
  inputDebounce: number
  formChangeDebounce: number
  dialogAnimationTime: number
  dialogAnimationFadeTime: number
  dateStringRepresentation: {
    always: string
    never: string
    earliest: string
    latest: string
  }
}
export interface IConfirmationMessages {
  deleted: string
  customerDuplicatedProspect: string
  quoteConvert: string
  salesOrderConvertProspect: string
}

export const confirmationMessages: IConfirmationMessages = {
  deleted: 'The item will be deleted. Are you sure?',
  customerDuplicatedProspect: 'This number is currently assigned to a Prospect. Do you want to convert the Prospect to a Customer?',
  quoteConvert: 'This Quote is for a Prospect, not a Customer. Do you want to convert Prospect to a Customer?',
  salesOrderConvertProspect: 'Sales Order can be created only by Customer. Do you want to convert this Prospect to a Customer?',
}

export const globalConstants: IGlobalConstants = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  dateFormat: 'MM/dd/yyyy',
  serverDateFormat: 'yyyy-MM-dd',
  paginationLimit: 10,
  inputDebounce: 700,
  dialogAnimationTime: 700,
  dialogAnimationFadeTime: 400,
  formChangeDebounce: 300,
  dateStringRepresentation: {
    always: 'Always',
    never: 'Never',
    earliest: 'Earliest',
    latest: 'Latest',
  },
}

export const projectVersion: string = process.env.REACT_APP_VERSION ?? ''

export const xsMq = `(max-width:${breakpoints.xs})`
export const smMq = `(max-width:${breakpoints.sm})`
export const mdMq = `(max-width:${breakpoints.md})`
export const lgMq = `(max-width:${breakpoints.lg})`

// TODO update me
export const maskDefinitions = {
  letter: {
    symbol: '#',
    pattern: /\p{L}/gu,
  },
  digit: {
    symbol: '0',
    pattern: /[0-9]/,
  },
  any: {
    symbol: '*',
    pattern: /./,
  },
}

export const inputMaskDefinitions = {
  [maskDefinitions.letter.symbol]: maskDefinitions.letter.pattern,
  [maskDefinitions.digit.symbol]: maskDefinitions.digit.pattern,
  [maskDefinitions.any.symbol]: maskDefinitions.any.pattern,
}

export const phoneNumberMask = '000000000000000'
