import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { Scope } from 'core/injector/injector.types'
import { CoreModule } from 'core/core.module'
import { errorHandlerServiceToken } from 'core/core.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { ISettingsService } from 'setup/settings/settings.service'
import {
  setupCrmModule,
  setupCrmUtilsServiceToken,
  setupOpportunitySourcesServiceToken,
  setupOpportunityStagesServiceToken,
  setupOpportunityTypesServiceToken,
} from './crm.constants'
import { IOpportunitySourcesService } from './master-information-tab/sources/opportunity-sources.service'
import { IOpportunityTypesService } from './master-information-tab/opportunity-types/opportunity-types.service'
import { IOpportunityStagesService } from './master-information-tab/opportunity-stages/opportunity-stages.service'
import { ICrmUtilsService } from './crm-utils.service'
import { SettingsModule } from '../settings/settings.module'
import { settingsServiceToken } from '../settings/settings.constants'

export type SetupCrmModuleDefinition = typeof import('./crm.exports')

export interface ISetupCrmModuleServices {
  CrmUtilsService: ICrmUtilsService
  OpportunityStagesService: IOpportunityStagesService
  OpportunityTypesService: IOpportunityTypesService
  OpportunitySourcesService: IOpportunitySourcesService
}

function setupCrmModuleInitializer({
  CrmUtilsService,
  OpportunityTypesService,
  OpportunitySourcesService,
  OpportunityStagesService,
}: SetupCrmModuleDefinition): void {
  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

  Injector.register<IOpportunityTypesService>(
    setupOpportunityTypesServiceToken,
    () => new OpportunityTypesService(httpClient),
    Scope.Singleton
  )
  Injector.register<IOpportunitySourcesService>(
    setupOpportunitySourcesServiceToken,
    () => new OpportunitySourcesService(httpClient),
    Scope.Singleton
  )
  Injector.register<IOpportunityStagesService>(
    setupOpportunityStagesServiceToken,
    () => new OpportunityStagesService(httpClient),
    Scope.Singleton
  )

  Injector.register<ICrmUtilsService>(
    setupCrmUtilsServiceToken,
    (injector) => {
      const opportunityTypesService = injector.resolve<IOpportunityTypesService>(setupOpportunityTypesServiceToken)
      const opportunitySourcesService = injector.resolve<IOpportunitySourcesService>(setupOpportunitySourcesServiceToken)
      const opportunityStagesService = injector.resolve<IOpportunityStagesService>(setupOpportunityStagesServiceToken)
      const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
      const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
      const settingsService = Injector.resolve<ISettingsService>(settingsServiceToken)

      return new CrmUtilsService(
        opportunityStagesService,
        opportunityTypesService,
        opportunitySourcesService,
        documentsService,
        settingsService,
        errorHandler
      )
    },
    Scope.Singleton
  )
}

export const SetupCrmModule: ILazyModule<SetupCrmModuleDefinition> = {
  name: setupCrmModule,
  resolver: () => import('./crm.exports'),
  initializer: setupCrmModuleInitializer,
  dependencies: [CoreModule, DocumentsModule, SettingsModule],
}
