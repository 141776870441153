/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IXtTableRowType } from './table-row.types'
import { cls } from '../../../common/utils/utils'
import { definePositionStyles, getFieldValue } from '../table.utils'

const tableCellClasses = { root: cls('xt-table-cell', 'xt-table-cell-body') }
const tableRowClasses = {
  root: 'xt-table-row',
  selected: 'xt-table-row--selected',
}

export const XtTableRow: IXtTableRowType = ({ item, columns, onClick, withDnd, index, rowClassName, selected = false }) => {
  const onRowClick = useCallback(() => onClick?.(item), [item, onClick])
  // TODO id must be unique !!!
  return (
    <Draggable isDragDisabled={!withDnd} key={item.id} draggableId={item.id?.toString()} index={index}>
      {(provided) => (
        <TableRow
          selected={selected}
          classes={tableRowClasses}
          component="div"
          className={rowClassName}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={onRowClick}
          tabIndex={-1}
        >
          {columns.map(({ field, renderCell, id, width, height, align, flex, sticky, converter }) => {
            if (field) {
              const fieldValue = getFieldValue(item, field.toString(), converter)
              return (
                <TableCell
                  classes={tableCellClasses}
                  title={fieldValue}
                  component="div"
                  style={{ width, flex, height, ...definePositionStyles(sticky) }}
                  align={align}
                  key={id}
                >
                  {fieldValue}
                </TableCell>
              )
            }
            if (renderCell) {
              return (
                <TableCell
                  classes={tableCellClasses}
                  component="div"
                  style={{ width, flex, height, ...definePositionStyles(sticky) }}
                  align={align}
                  key={id}
                >
                  {renderCell(item)}
                </TableCell>
              )
            }
            return null
          })}
        </TableRow>
      )}
    </Draggable>
  )
}
