import { AttachedDocumentTargetType, IAttachedDocument, IAttachedDocumentWithFile } from 'documents/documents.types'
import { AttachedDocumentAction, IAttachedDocumentsState } from './documents.types'
import { IXtTableCellAction } from '../../components/table/table-cell-actions/table-cell-actions'
import { defaultDocumentState, initialPreviewImgState } from './documents.constants'

export const makeNewDocumentState = (): IAttachedDocumentsState => ({
  editedDocument: defaultDocumentState,
  isLoading: false,
  isDialogOpen: true,
  disableFileUpload: false,
  previewImg: initialPreviewImgState,
})

export function convertDocumentFile(file: File, index: number): IAttachedDocumentWithFile {
  return { ...defaultDocumentState, file, id: Date.now() - index, name: file.name }
}
export function getDocumentActions(item: IAttachedDocument, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  return item && item.target_type !== AttachedDocumentTargetType.File
    ? actions.filter((action: IXtTableCellAction) => action.name === AttachedDocumentAction.Delete)
    : actions
}
