import React, { useMemo, useRef } from 'react'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Select } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { nanoid } from 'nanoid'
import { cls } from 'common/utils/utils'
import { IXtSelectProps } from './xt-select.types'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'
import { renderSelectOption } from './xt-select.utils'

export function XtSelect<Option extends IXtAutocompleteOption>({
  label,
  value,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  onBlur,
  multiple,
  clearable,
  renderOption = renderSelectOption,
}: IXtSelectProps<Option>): React.ReactElement {
  const componentIdRef = useRef<string>(nanoid())
  const id = componentIdRef.current

  const renderedOptions = useMemo(() => options.map(renderOption), [options, renderOption])

  return (
    <FormControl
      hidden={hidden}
      className={cls('MuiFormField', 'MuiFormFieldSelect', className, value ? 'MufFormSelectFilled' : 'MufFormSelectEmpty')}
      variant="outlined"
      error={!!error}
    >
      <div>
        <div>
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            value={(options.length && value) || ''}
            title={Array.isArray(value) ? value.join(', ') : ''}
            onBlur={onBlur}
            onChange={(e) => onChange && onChange(e.target.value as string)}
            disabled={disabled}
            label={label}
            MenuProps={{
              PopoverClasses: { root: 'xtSelectMenu' },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            multiple={multiple}
            endAdornment={
              clearable && value && !disabled ? (
                <InputAdornment position="end">
                  <IconButton
                    className="MuiClearableFilterClearButton"
                    title="Clear"
                    onClick={() => {
                      if (typeof onChange === 'function') {
                        onChange(null)
                      }
                      if (typeof onBlur === 'function') {
                        onBlur()
                      }
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          >
            {renderedOptions}
          </Select>
        </div>
        <FormHelperText hidden={!error}>{error}</FormHelperText>
      </div>
    </FormControl>
  )
}
