import { ILazyModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from '../core/injector/injector.service'
import { httpClientToken, IHttpClient } from '../core/services/http-client'
import { Scope } from '../core/injector/injector.types'
import { IPurchaseOrdersService } from './purchase-orders.service'
import { purchaseOrdersModule, purchaseOrdersServiceToken, purchaseOrdersUtilsServiceToken } from './purchase-orders.constants'
import { IPurchaseOrdersUtilsService } from './purchase-orders-utils.service'
import { DocumentsModule } from '../documents/documents.module'
import { CoreModule } from '../core/core.module'

export type PurchaseOrdersModuleDefinition = typeof import('./purchase-orders.exports')

export interface IPurchaseOrdersModuleServices {
  PurchaseOrdersService: IPurchaseOrdersService
  PurchaseOrdersUtilsService: IPurchaseOrdersUtilsService
}

export const PurchaseOrdersModule: ILazyModule<PurchaseOrdersModuleDefinition> = {
  name: purchaseOrdersModule,
  resolver: () => import('./purchase-orders.exports'),
  initializer: ({ PurchaseOrdersService, PurchaseOrdersUtilsService }) => {
    Injector.register<IPurchaseOrdersService>(
      purchaseOrdersServiceToken,
      (injector) => new PurchaseOrdersService(injector.resolve<IHttpClient>(httpClientToken)),
      Scope.Singleton
    )
    Injector.register<IPurchaseOrdersUtilsService>(
      purchaseOrdersUtilsServiceToken,
      (injector) => new PurchaseOrdersUtilsService(injector.resolve<IPurchaseOrdersService>(purchaseOrdersServiceToken)),
      Scope.Singleton
    )
  },
  dependencies: [DocumentsModule, CoreModule],
}
