import * as React from 'react'
import { FC, useState, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { xsMq, confirmationMessages } from 'common/constants'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import {
  IAppliedCharacteristic,
  AppliedCharacteristicAction,
  ICharacteristicDialogState,
  IDeletionDialog,
  IXTCharacteristics,
  CharacteristicDialogFormField,
  IAppliedCharacteristicNew,
} from './characteristics.types'
import { AppliedCharacteristicActionsEditMode, characteristicColumns } from './characteristics.constants'
import * as styles from './characteristics.module.scss'
import { XtList } from '../components/list/list'
import { CharacteristicDialog } from './characteristic-dialog/characteristic-dialog'
import { XtConfirmationDialog } from '../components/xt-confirmation-dialog/xt-confirmation-dialog'
import { defaultCharacteristicState, defaultDeletionState } from './characteristics.utils'
import { XtDialog, XtDialogAnimation } from '../components/xt-dialog/xt-dialog'
import { useCharacteristicsModule } from './characteristics-module-hook'

/**
 *  XtCharacteristics displays a list of characteristics applied to a certain entity (the entity type is
 *  determined by the usedOn property) with the ability to add new characteristics and edit the existing ones.
 */
export const XtCharacteristics: FC<IXTCharacteristics> = React.memo(
  ({ characteristics, disabled: isViewMode = false, usedOnFilter, onDelete, onUpdate, onCreate }) => {
    const { CharacteristicsService } = useCharacteristicsModule()
    const [characteristicDialog, setCharacteristicDialog] = useState<ICharacteristicDialogState>(defaultCharacteristicState)
    const [deletionState, setDeletionDialog] = useState<IDeletionDialog>(defaultDeletionState)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isMobile = useMediaQuery(xsMq)

    const openCharacteristicDialogEditMode = async (item: IAppliedCharacteristic): Promise<void> => {
      const characteristicData = await CharacteristicsService.get(item.characteristic)
      setCharacteristicDialog({
        open: true,
        editMode: true,
        characteristic: {
          [CharacteristicDialogFormField.Characteristic]: characteristicData,
          [CharacteristicDialogFormField.Value]: item.characteristic_value,
        },
        characteristicId: item.id,
      })
    }

    const onAction = useCallback((item: IAppliedCharacteristic, action: AppliedCharacteristicAction) => {
      switch (action) {
        case AppliedCharacteristicAction.Delete: {
          return setDeletionDialog({ item, open: true })
        }
        case AppliedCharacteristicAction.Edit:
          return openCharacteristicDialogEditMode(item)
        default:
          return null
      }
    }, [])

    const executeAction = async (action: Promise<void>): Promise<void> => {
      setIsLoading(true)
      await action
      setIsLoading(false)
    }

    const onOpenCharacteristicDialog = useCallback(
      () =>
        setCharacteristicDialog((prev) => ({
          ...prev,
          open: true,
          editMode: false,
        })),
      []
    )

    const onCloseCharacteristicDialog = useCallback(() => setCharacteristicDialog(defaultCharacteristicState), [])

    const onConfirmCharacteristicDialog = useCallback(
      (formData: IAppliedCharacteristicNew) => {
        if (
          characteristicDialog.editMode &&
          characteristicDialog.characteristicId !== null &&
          characteristicDialog.characteristicId !== undefined
        ) {
          void executeAction(onUpdate({ ...formData, id: characteristicDialog.characteristicId }))
        } else {
          // negative IDs are used for newly-added characteristics, to avoid clashes with positive previous ones
          void executeAction(onCreate({ ...formData, id: -new Date().getTime() }))
        }
        onCloseCharacteristicDialog()
      },
      [characteristicDialog, onCloseCharacteristicDialog, onUpdate, onCreate]
    )

    const disabled = isViewMode || isLoading

    const handleRowClick = useCallback<(item: IAppliedCharacteristic) => Promise<void>>(
      async (item) => {
        if (disabled) {
          return
        }
        await openCharacteristicDialogEditMode(item)
      },
      [disabled]
    )

    const closeConfirmationDialog = useCallback(() => setDeletionDialog(defaultDeletionState), [])
    const handleDeletion = useCallback(() => {
      if (deletionState.item) {
        void executeAction(onDelete(deletionState.item))
      }
      closeConfirmationDialog()
    }, [deletionState.item, closeConfirmationDialog, onDelete])

    return (
      <div className={styles.characteristics}>
        <XtConfirmationDialog
          open={deletionState.open}
          message={confirmationMessages.deleted}
          title="Delete Characteristic"
          confirmationButtonLabel="Delete"
          onConfirm={handleDeletion}
          onClose={closeConfirmationDialog}
        />
        <XtDialog className="xt-dialog-details-content" open={characteristicDialog.open} animation={XtDialogAnimation.FadeAnimation}>
          <CharacteristicDialog
            onConfirm={onConfirmCharacteristicDialog}
            characteristicState={characteristicDialog.characteristic}
            usedOnFilter={usedOnFilter}
            onClose={onCloseCharacteristicDialog}
          />
        </XtDialog>

        <XtResponsiveButton
          disabled={disabled || isViewMode}
          onClick={onOpenCharacteristicDialog}
          icon={SvgIconIds.ADD_CIRCLE}
          className={styles.characteristicsAddButton}
          loading={isLoading}
          label="Add Characteristic"
        />

        <div className={styles.characteristicsList}>
          <XtList
            loading={isLoading}
            actions={disabled ? [] : AppliedCharacteristicActionsEditMode}
            data={characteristics}
            isMobile={isMobile}
            onAction={onAction}
            columns={characteristicColumns}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    )
  }
)
