import { IAsyncRoute, IRoute } from '../common/routing/routing.types'
import { tasksCalendarRoutePath, tasksRoutePath } from './tasks.constants'
import { TasksGuard } from './tasks.guard'
import { XtMode } from '../common/common.types'
import { buildPageComponent } from '../common/routing/routing.utils'
import { TaskDetailsGuard } from './task-details/task-details.guard'
import { TasksModule, TasksModuleDefinition } from './tasks.module'

export function tasksRouteFactory({ TaskListPage, TaskDetailsPage, TaskDetailsOpportunityNewPage }: TasksModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: TaskListPage,
    },
    {
      path: `/:id/${XtMode.Edit}`,
      component: (props) => buildPageComponent(props, XtMode.Edit, TaskDetailsPage),
      guards: [TaskDetailsGuard],
    },
    {
      path: `/:id/${XtMode.View}`,
      component: (props) => buildPageComponent(props, XtMode.View, TaskDetailsPage),
    },
    {
      path: `/${XtMode.New}/opportunity/:parentNumber`,
      component: (props) => buildPageComponent(props, XtMode.New, TaskDetailsOpportunityNewPage),
      guards: [TaskDetailsGuard],
    },
    {
      path: `/${XtMode.New}`,
      component: (props) => buildPageComponent(props, XtMode.New, TaskDetailsPage),
      guards: [TaskDetailsGuard],
    },
  ]
}

export const tasksRoute: IAsyncRoute<TasksModuleDefinition> = {
  path: tasksRoutePath,
  name: 'Tasks',
  module: TasksModule,
  guards: [TasksGuard],
  childrenRoutesFactory: tasksRouteFactory,
}

export const tasksCalendarRoute: IAsyncRoute<TasksModuleDefinition> = {
  path: tasksCalendarRoutePath,
  name: 'Calendar',
  module: TasksModule,
  guards: [TasksGuard],
  childrenRoutesFactory: ({ TasksCalendar }: TasksModuleDefinition) => [
    {
      path: '',
      exact: true,
      component: TasksCalendar,
    },
  ],
}
