import { FC, MouseEventHandler } from 'react'
import * as React from 'react'
import { IconButton } from '@material-ui/core'
import { SvgIcon } from '../../../svg-icon/svg-icon'
import { SvgIconIds } from '../../../svg-icon/svg-icon.types'
import * as styles from './table-cell-button.module.scss'
import { cls } from '../../../../common/utils/utils'

export interface IXtTableCellButtonParams {
  icon: SvgIconIds
  onClick?: MouseEventHandler
  title?: string
  className?: string
  hidden?: boolean
}

export const XtTableCellButton: FC<IXtTableCellButtonParams> = ({ icon, title, onClick, hidden = false, className }) => (
  <IconButton title={title} onClick={onClick} hidden={hidden} className={cls(styles.xtTableCellButton, className)}>
    <SvgIcon iconId={icon} />
  </IconButton>
)
