import { IAttachedDocumentWithFile, IAttachedDocument } from 'documents/documents.types'

export interface IAttachedDocumentDialog {
  onClose: () => void
  onCreate: (newDocument: IAttachedDocumentWithFile) => Promise<boolean>
  onUpdate: (attachedDocument: IAttachedDocument) => Promise<boolean>
  editedDocument: IAttachedDocument | null
  disabled?: boolean
  disableFileUpload: boolean
}

export enum DocumentDialogFormLabel {
  Notes = 'Notes',
  Name = 'Name',
}

export enum DocumentDialogFormField {
  Notes = 'notes',
  Name = 'name',
  File = 'file',
}

export interface IDocumentDialogFormState {
  [DocumentDialogFormField.Notes]: string
  [DocumentDialogFormField.Name]: string
  [DocumentDialogFormField.File]: File | null
}
