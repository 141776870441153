import * as Yup from 'yup'
import { maxNumber, minDate, validationMessage } from 'common/validation/validation'
import { CharacteristicDialogFormField, CharacteristicType, ICharacteristic } from '../characteristics.types'
import { convertStringToRegex, isValidRegex } from '../../common/utils/regexp.utils'

export const CharacteristicDialogFormSchema = Yup.object().shape({
  [CharacteristicDialogFormField.Characteristic]: Yup.object()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [CharacteristicDialogFormField.Value]: Yup.mixed().when(
    CharacteristicDialogFormField.Characteristic,
    (characteristic: ICharacteristic) => {
      if (!characteristic) {
        return Yup.mixed()
      }

      switch (characteristic.characteristic_type) {
        case CharacteristicType.Text: {
          const schema = Yup.string().required(validationMessage.isRequired)
          const { characteristic_validator: pattern } = characteristic
          const regExp = pattern && isValidRegex(pattern) ? convertStringToRegex(pattern) : null
          return regExp ? schema.matches(regExp, validationMessage.invalidFormat) : schema
        }
        case CharacteristicType.List:
          return Yup.string().required(validationMessage.isRequired)
        case CharacteristicType.Date:
          return Yup.date()
            .required(validationMessage.isRequired)
            .typeError(validationMessage.invalidDate)
            .min(new Date(minDate), validationMessage.minDate(validationMessage.minimumDate))
        case CharacteristicType.Number:
          return Yup.number()
            .max(maxNumber, validationMessage.max(maxNumber))
            .required(validationMessage.isRequired)
            .typeError(validationMessage.invalidNumber)
        default:
          return Yup.mixed().required(validationMessage.isRequired)
      }
    }
  ),
})
