import { useCallback, useEffect } from 'react'
import { ICommentsHook, useComments } from '../comments/comments-hook'
import { DocumentType } from '../../documents/documents.types'
import { useFormControlState } from '../../common/hooks/form/form-control.hook'

export interface IRemarksHook extends ICommentsHook {
  notes: string
  additionalNotes: string
  isDirty: boolean

  /**
   * Resets the remarks state. Should be used to reset isDirty state.
   * @param notes
   * @param additionalNotes
   */
  reset(notes: string, additionalNotes?: string): void
  setNotes(notes: string): void
  setAdditionalNotes(notes: string): void
}

export function useRemarks(
  source: DocumentType,
  sourceNumber: string | null | undefined,
  initialNotes: string = '',
  initialAdditionalNotes: string = ''
): IRemarksHook {
  const commentsState = useComments(source, sourceNumber)
  const notesState = useFormControlState<string>(initialNotes)
  const additionalNotesState = useFormControlState<string>(initialAdditionalNotes)

  const reset = useCallback<(notes: string, additionalNotes?: string) => void>((notes, additionalNotes = '') => {
    notesState.reset(notes)
    additionalNotesState.reset(additionalNotes)
    commentsState.setComments([])
  }, [])

  // TODO: need to update the way to set the initial state
  useEffect(() => {
    notesState.reset(initialNotes)
    additionalNotesState.reset(initialAdditionalNotes)
  }, [initialNotes, initialAdditionalNotes])

  return {
    notes: notesState.value,
    additionalNotes: additionalNotesState.value,
    setNotes: notesState.setValue,
    setAdditionalNotes: additionalNotesState.setValue,
    reset,
    isDirty: commentsState.isDirtyComments || notesState.isDirty || additionalNotesState.isDirty,
    ...commentsState,
  }
}
