import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { Scope } from 'core/injector/injector.types'
import { DocumentsModule } from 'documents/documents.module'
import { CoreModule } from 'core/core.module'
import { errorHandlerServiceToken } from 'core/core.constants'
import { ISettingsService } from 'setup/settings/settings.service'
import { IErrorHandler } from 'core/services/error-handler.service'
import {
  setupCustomerTypesServiceToken,
  setupSalesModule,
  setupSalesUtilsServiceToken,
  setupSaleTypesServiceToken,
} from './sales.constants'
import { ISaleTypesService } from './master-information-tab/sale-types/sale-types.service'
import { ICustomerTypesService } from './master-information-tab/customer-types/customer-types.service'
import { ISalesUtilsService } from './sales-utils.service'
import { SettingsModule } from '../settings/settings.module'
import { settingsServiceToken } from '../settings/settings.constants'

export type SetupSalesModuleDefinition = typeof import('./sales.exports')

export interface ISetupSalesModuleServices {
  SalesUtilsService: ISalesUtilsService
  CustomerTypesService: ICustomerTypesService
  SaleTypesService: ISaleTypesService
}

function salesModuleInitializer({ SaleTypesService, CustomerTypesService, SalesUtilsService }: SetupSalesModuleDefinition): void {
  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

  Injector.register<ISaleTypesService>(setupSaleTypesServiceToken, () => new SaleTypesService(httpClient), Scope.Singleton)

  Injector.register<ICustomerTypesService>(setupCustomerTypesServiceToken, () => new CustomerTypesService(httpClient), Scope.Singleton)

  Injector.register<ISalesUtilsService>(
    setupSalesUtilsServiceToken,
    (injector) => {
      const saleTypesService = injector.resolve<ISaleTypesService>(setupSaleTypesServiceToken)
      const customerTypesService = injector.resolve<ICustomerTypesService>(setupCustomerTypesServiceToken)
      const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
      const settingsService = Injector.resolve<ISettingsService>(settingsServiceToken)
      const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)

      return new SalesUtilsService(saleTypesService, documentsService, customerTypesService, settingsService, errorHandler)
    },
    Scope.Singleton
  )
}

export const SetupSalesModule: ILazyModule<SetupSalesModuleDefinition> = {
  name: setupSalesModule,
  resolver: () => import('./sales.exports'),
  initializer: salesModuleInitializer,
  dependencies: [CoreModule, DocumentsModule, SettingsModule],
}
